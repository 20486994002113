import { FunctionComponent, ReactElement, useState } from 'react';

import { cn } from '@/lib/utils';

const SectionDivider: FunctionComponent<SectionDividerProps> = ({
  titles = ['Text Title'],
  subTitle = '',
  rowTitle = '',
  onTitleClick = (arg) => {},
  img = '',
  color,
  textClass = '',
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className="flex w-full items-end justify-between border-b border-gray-200 pb-[10px]">
      <div className="flex items-center">
        {img && (
          <div
            className={`mr-4 flex size-[32px] items-center justify-center rounded-full ${color}`}
            style={{ backgroundColor: color }}
          >
            {img}
          </div>
        )}
        {titles.map((item, index) => (
          <p
            key={`tab_${index}`}
            className={cn(`relative mr-[50px] text-xl font-semibold`, textClass)}
            onClick={() => {
              setSelectedTab(index);
              onTitleClick(index);
            }}
          >
            {item} <span className="font-normal opacity-60">{subTitle}</span>
            {!!(titles.length > 1 && index == selectedTab) && (
              <div className="absolute bottom-0 mb-[-13px] h-[5px] w-full rounded-[15px] bg-primary" />
            )}
          </p>
        ))}
        <p>{rowTitle}</p>
      </div>
    </div>
  );
};

export default SectionDivider;

type SectionDividerProps = {
  titles: string[];
  subTitle?: string;
  rowTitle?: string;
  onTitleClick?: (arg) => {};
  img: ReactElement | '';
  color?: string;
  textClass?: string;
};

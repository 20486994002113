import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import config from '@/config';
import useFirebaseAuthSetup from '@/hooks/use-firebase-auth-setup';

import { Toaster } from '../ui/toaster';

function SimpleLayout() {
  useFirebaseAuthSetup();

  // theme setup
  useEffect(() => {
    if (config.isMetaworks) {
      const root = document.documentElement;
      root.setAttribute('data-theme', 'metaworks');
    }
  }, []);

  return (
    <>
      <Outlet />
      <Toaster />
    </>
  );
}

export default SimpleLayout;

import { ExecutionResult } from 'graphql-ws';

import { client, ClientName } from '@/lib/apollo';
import useBoundStore from '@/store';
import { ApolloError, ObservableSubscription } from '@apollo/client';

import {
  ProjectPubSubEventDocument,
  ProjectPubSubEventSubscription,
  SubscriptionType,
} from '../__generated__/marketplace/graphql';

export const subscribeToProjectChannel = (): ObservableSubscription => {
  const observer = client.subscribe({
    query: ProjectPubSubEventDocument,
    context: {
      clientName: ClientName.Marketplace,
    },
  });

  const onNext = (result: ExecutionResult<ProjectPubSubEventSubscription, unknown>) => {
    if (result.data) {
      console.debug('🦊 | subscriptions | onNext | result:', result);
      const project = result.data.project;
      console.log('🦛 | subscriptions | data.project', project);

      switch (project?.type) {
        case SubscriptionType.CreateNft: {
          const dispatchUpdateAsset = useBoundStore.getState().assetsSlice.dispatchUpdateAsset;
          dispatchUpdateAsset(project.payload.nfts[0]);
          break;
        }
        case SubscriptionType.CreateCollection: {
          if (project.payload.success) {
            const dispatchUpdateCollection =
              useBoundStore.getState().collectionsSlice.dispatchUpdateCollection;
            dispatchUpdateCollection(project?.payload?.collection);
          }
          break;
        }
        case SubscriptionType.CreateRealEstate: {
          const dispatchUpdateRealEstate =
            useBoundStore.getState().realEstateSlice.dispatchUpdateRealEstate;
          dispatchUpdateRealEstate(project.payload.real_estate);
          break;
        }
        case SubscriptionType.VideoProcessed: {
          const reloadMyContent = useBoundStore.getState().reloadSlice.reloadMyContent;
          const updateFile = useBoundStore.getState().filesSlice.updateFile;

          if (project.payload.success) {
            reloadMyContent();
            localStorage.setItem('uploadedVideo', JSON.stringify(project.payload.upload));
            updateFile(project.payload.upload.name, project.payload.upload);
          }
          // if (!project.payload.success) {
          //   localStorage.setItem('uploadedVideo', `${project.payload.upload.accessLink}`);
          // }
          break;
        }
        case SubscriptionType.ExportUsersUploaded:
          if (!project.payload.success) {
            console.log('🦛 | PUBSUB - export_users_uploaded', project.payload);
            window.open(project.payload.download_link);
          }
          break;
        default:
          break;
      }
    }
  };

  const onErr = (error: ApolloError) => {
    console.log('🦛 | subscribeToProjectChannel | error:', error);
  };

  const onComplete = () => {
    console.log('🦛 | subscribeToProjectChannel | completed', '');
  };

  const subscription = observer.subscribe(onNext, onErr, onComplete);

  return subscription;
};

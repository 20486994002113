import { useMemo } from 'react';
import { ethers } from 'ethers';
import spacetime from 'spacetime';

import CopyText from '@/components/ui/copy-text'; // Adjust the import path as needed
import { polygonScanLink } from '@/const';

const formatNumber = (num, erc = 0) => {
  if (erc == 1155) {
    return (parseFloat(num) / 100)?.toFixed(num < 0.001 ? 4 : num < 0.01 ? 3 : 2);
  } else {
    return parseFloat(num)?.toFixed(num < 0.001 ? 4 : num < 0.01 ? 3 : 2);
  }
};

const shortCalendar = (date) => {
  const target = spacetime(date);
  const now = spacetime.now();

  if (target.isSame(now, 'day')) {
    return `${target.format('{time-short}')}`;
  } else {
    return target.format('{month-short} {date-ordinal}');
  }
};

export const useRecentTransactionsTable = (
  userTransactions,
  formattedTypesObj,
  handleGetTransactionQuantity,
) => {
  return useMemo(
    () => ({
      columnNames: ['Asset name', 'Type', 'Quantity', 'Date', ''],
      data: userTransactions.slice(0, 4)?.map((item) => ({
        row: [
          { value: `${item?.asset_info?.name}`, className: 'text-[1rem] capitalize' },
          {
            value: `${formattedTypesObj[item?.receipt?.name] || item?.receipt?.name}`,
            className: 'text-[1rem]',
          },
          { value: handleGetTransactionQuantity(item), className: 'text-[1rem]' },
          {
            value: item?.receipt?.transaction_hash && (
              <p>{shortCalendar(spacetime(parseInt(item.created_at)))}</p>
            ),
            className: 'text-[1rem]',
          },
          {
            value: (
              <a
                className="learnMore text-[1rem] font-medium"
                href={polygonScanLink(item?.receipt?.transaction_hash)}
                target="_blank"
                rel="noreferrer"
              >
                View
              </a>
            ),
            className: 'text-[1rem] capitalize',
          },
        ],
      })),
    }),
    [userTransactions, formattedTypesObj, handleGetTransactionQuantity],
  );
};

export const useHoldingAssetsTable = (userAssets, formatNumber, navigate) => {
  return useMemo(
    () => ({
      columnNames: [
        'Item',
        'Name',
        'holding type',
        'Quantity',
        'Price per holding',
        'Total Investment',
      ],
      data: userAssets.map((item) => {
        console.log({ userAssets });
        return {
          accordion: item?.symbol === '3NT',
          row: [
            {
              value: (
                <img
                  className="size-[64px] rounded-[5px]"
                  src={`${item?.icon_url}`}
                  onClick={() => {
                    navigate(`/asset/${item?.nft_id}`);
                  }}
                />
              ),
              className: 'text-[1rem] capitalize',
            },
            { value: item.name, className: 'text-[1rem] capitalize' },
            { value: 'Shares', className: 'text-[1rem] capitalize' },
            {
              value: parseFloat(item.quantity).toLocaleString(),
              className: 'text-[1rem] capitalize',
            },
            {
              value: `$${formatNumber(parseFloat(item.dollarBalance) / parseFloat(item.quantity), item.erc)}`,
              className: 'text-[1rem] capitalize',
            },
            {
              value: `$${formatNumber(parseFloat(item.dollarBalance).toFixed(2).toLocaleString(), item.erc)}`,
              className: 'text-[1rem] capitalize',
            },
          ],
          accordionRows:
            item?.symbol === '3NT'
              ? userAssets
                  .filter((row) => row?.symbol === 'NNN')
                  .map((item) => [
                    {
                      value: (
                        <img
                          className="ml-[7px] size-[45px] rounded-[5px]"
                          src={`${item?.icon_url}`}
                          onClick={() => {
                            navigate(`/asset/${item?.nft_id}`);
                          }}
                        />
                      ),
                      className: 'text-[14px] capitalize',
                    },
                    { value: item.name, className: 'text-[14px] capitalize opacity-50' },
                    { value: 'Shares', className: 'text-[14px] capitalize opacity-50' },
                    {
                      value: parseFloat(item.quantity).toLocaleString(),
                      className: 'text-[14px] capitalize opacity-50',
                    },
                    {
                      value: `$${formatNumber(parseFloat(item.dollarBalance) / parseFloat(item.quantity), item.erc)}`,
                      className: 'text-[14px] capitalize opacity-50',
                    },
                    {
                      value: `$${formatNumber(parseFloat(item.dollarBalance).toFixed(2).toLocaleString(), item.erc)}`,
                      className: 'text-[14px] capitalize opacity-50',
                    },
                  ])
              : [],
        };
      }),
    }),
    [userAssets, formatNumber, navigate],
  );
};

export const useAllTransactionsTable = (
  userTransactions,
  formattedTypesObj,
  handleGetTransactionQuantity,
  polygonScanLink,
) => {
  return useMemo(
    () => ({
      columnNames: ['Item', 'Name', 'Type', 'Quantity', 'Transaction Hash', 'Date', ''],
      data: userTransactions.map((item) => ({
        row: [
          {
            value: (
              <img
                className="size-[64px] rounded-[5px]"
                src={item?.asset_info?.icon_url || item?.asset_info?.media_ipfs_link}
              />
            ),
            className: 'text-[1rem] capitalize',
          },
          { value: item?.asset_info?.name, className: 'text-[1rem] capitalize' },
          {
            value: formattedTypesObj[item?.receipt?.name] || item?.receipt?.name,
            className: 'text-[1rem] capitalize',
          },
          { value: handleGetTransactionQuantity(item), className: 'text-[1rem] capitalize' },
          {
            value: (
              <CopyText
                text={`${item?.receipt?.transaction_hash.slice(0, 5)}...${item?.receipt?.transaction_hash.slice(-5)}`}
                valueToCopy={item?.receipt?.transaction_hash}
                className="text-[1rem]"
              />
            ),
            className: 'text-[1rem] capitalize',
          },
          {
            value: spacetime(parseInt(item.created_at)).format(
              '{date-ordinal} {month-short} {time}',
            ),
            className: 'text-[1rem] capitalize',
          },
          {
            value: (
              <a
                className="learnMore text-[1rem] font-medium"
                href={polygonScanLink(item?.receipt?.transaction_hash)}
                target="_blank"
                rel="noreferrer"
              >
                View
              </a>
            ),
            className: 'text-[1rem] capitalize',
          },
        ],
      })),
    }),
    [userTransactions, formattedTypesObj, handleGetTransactionQuantity],
  );
};

import { useCallback, useMemo } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Control, Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import InputColor from 'react-input-color';
import PhoneInput from 'react-phone-number-input';
import TimePicker from 'react-time-picker';

import Add from '@/assets/Add.svg?react';
import Clock from '@/assets/Clock.svg?react';
import { CalendarForm } from '@/components/ui/calendar';
import { Dropzone } from '@/components/ui/dropzone';
import MyEditor from '@/components/ui/editor';
import { Input } from '@/components/ui/input';
import Location from '@/components/ui/location-input';
import PercentSlider from '@/components/ui/percent-slider';
import { RadioGroup } from '@/components/ui/radio-group';
import { SelectorDropdown } from '@/components/ui/selector-dropdown';
import { Switch } from '@/components/ui/switch.tsx';
import VideoUpload from '@/components/ui/video-upload';
import { cn } from '@/lib/utils';

import { AddButton } from './add-button';
import { DeleteButton } from './delete-button';

import 'react-phone-number-input/style.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

export const InputsSection = ({ data = [] }: { data: any[] }) => {
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = useFormContext();

  const formValues = watch();

  const renderStyle = (field, item_) => {
    return {
      paddingLeft: field?.numOfIndents ? field?.numOfIndents * 24 : '',
      width: field?.halfWidth ? '48%' : '100%',
      opacity: formValues[item_.toggleState] === false ? '0.5' : '',
    };
  };

  const cloudflareLink = (cloudflare_uid) =>
    `https://customer-t2pt9vez6v5e9856.cloudflarestream.com/${cloudflare_uid}/manifest/video.m3u8`;

  const renderElement = (field: any, item_: any, elementIndex: number) => {
    const error = errors[field?.state];
    let underText_ = field?.underText;

    if (error && error.type === 'required') {
      underText_ = 'required';
    } else if (error?.message) {
      underText_ = error?.message as string;
    }
    if (
      !Object.prototype.hasOwnProperty.call(field, 'showIf') ||
      !!((field?.showIf && field?.showIf(formValues[field?.showBasedOn])) == true)
    ) {
      return (
        <div
          className="sectionInputWidth w-full"
          key={`element_${elementIndex}`}
          style={renderStyle(field, item_)}
        >
          {(() => {
            switch (field?.type) {
              case 'input':
                return (
                  <div>
                    <Input
                      {...register(field.state, {
                        required: field?.requiredForNextStep ? 'This field is required' : false,
                        minLength: {
                          value: field?.minLength,
                          message: `Your input must be at least ${field?.minLength} characters long`,
                        },
                      })}
                      placeholder={field?.placeholder}
                      label={field?.label}
                      underText={underText_}
                      isError={error}
                      type={field?.inputType}
                      callOnBlur={() =>
                        item_?.callOnBlur?.({ [field?.state]: formValues[field?.state] })
                      }
                    />
                  </div>
                );
              case 'currencyInput':
                return (
                  <div className={`w-full`}>
                    <Controller
                      control={control}
                      name={field?.state}
                      rules={{ required: field?.requiredForNextStep }}
                      render={({ field: _field }) => {
                        return (
                          <div className="flex flex-col">
                            <p className="mb-1 text-sm opacity-80">{field?.label}</p>
                            <div className="relative">
                              <CurrencyInput
                                id="input-example"
                                name="input-name"
                                placeholder={field?.placeholder}
                                prefix="$"
                                className={`w-full rounded-md border border-input bg-card px-[16px] py-[13px] text-[14px] ${error ? 'border border-red-500' : ''}`}
                                value={_field.value}
                                onValueChange={(value, name, values) => {
                                  console.log(value, name, values);
                                  _field.onChange(value);
                                }}
                              />
                            </div>
                          </div>
                        );
                      }}
                    />
                  </div>
                );
              case 'keyValueInput':
                return (
                  <div className="w-full">
                    <Controller
                      control={control}
                      name={field?.state}
                      rules={{ required: field?.requiredForNextStep }}
                      render={({ field: _field }) => (
                        <div className="flex flex-col gap-[10px]">
                          <p className="mb-1 text-sm opacity-80">{field?.label}</p>
                          {_field?.value?.map?.((item, index) => (
                            <div className="flex w-full items-end" key={index}>
                              <Input
                                className="w-full border"
                                containerClassName="mr-5"
                                placeholder={'Field'}
                                underText={underText_}
                                isError={error}
                                value={_field.value[index].field}
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  const temp = [..._field.value];
                                  temp[index].field = e.target.value;
                                  _field.onChange(temp);
                                }}
                              />
                              <Input
                                className="w-full border"
                                containerClassName="mr-5"
                                placeholder={'Value'}
                                underText={underText_}
                                isError={error}
                                value={_field.value[index].value}
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  const temp = [..._field.value];
                                  temp[index].value = e.target.value;
                                  _field.onChange(temp);
                                }}
                              />
                              {_field.value.length - 1 == index ? (
                                <AddButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    _field.onChange([..._field.value, { field: '', value: '' }]);
                                  }}
                                />
                              ) : (
                                <DeleteButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const tempProperties = [..._field.value];
                                    tempProperties.splice(index, 1);
                                    console.log({ tempProperties });
                                    _field.onChange(tempProperties);
                                  }}
                                />
                              )}
                              {/*  */}
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </div>
                );
              case 'editorInput':
                return (
                  <div className="w-full">
                    <Controller
                      control={control}
                      name={field?.state}
                      rules={{ required: field?.requiredForNextStep }}
                      render={({ field: _field }) => (
                        <div className="flex flex-col gap-[10px]">
                          <p className="mb-1 text-sm opacity-80">{field?.label}</p>
                          <div className="flex w-full items-end">
                            <MyEditor
                              isError={error}
                              editorState={_field.value}
                              setEditorState={(text) => {
                                _field.onChange(text);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    />
                  </div>
                );
              case 'timePicker':
                return (
                  <div className="w-full">
                    <Controller
                      control={control}
                      name={field?.state}
                      rules={{ required: field?.requiredForNextStep }}
                      render={({ field: _field }) => (
                        <div className="flex flex-col">
                          <p className="mb-1 text-sm opacity-80">{field?.label}</p>
                          <div className="timepicker flex w-full items-end">
                            <TimePicker
                              disableClock
                              onChange={_field.onChange}
                              value={_field.value}
                            />
                            <Clock />
                          </div>
                        </div>
                      )}
                    />
                  </div>
                );
              case 'textarea':
                return (
                  <div>
                    <Input
                      {...register(field.state, {
                        required: field?.requiredForNextStep ? 'This field is required' : false,
                        minLength: {
                          value: field?.minLength,
                          message: `Your input must be at least ${field?.minLength} characters long`,
                        },
                      })}
                      placeholder={field?.placeholder}
                      label={field?.label}
                      underText={underText_}
                      isError={error}
                      textarea={true}
                      maxLength={field?.maxLength}
                      callOnBlur={() =>
                        item_?.callOnBlur?.({ [field?.state]: formValues[field?.state] })
                      }
                    />
                  </div>
                );
              case 'dropdown':
                return (
                  <SelectorDropdown
                    key={field.defaultValue}
                    control={control}
                    selected={field.defaultValue}
                    name={field.state}
                    label={field?.label}
                    options={field?.options}
                    underText={underText_}
                    isError={error}
                    required={field?.requiredForNextStep}
                    callOnBlur={item_?.callOnBlur}
                    defaultValue={field?.defaultValue}
                  />
                );
              case 'calendar':
                return (
                  <CalendarForm
                    label={field?.label}
                    control={control}
                    name={field.state}
                    underText={underText_}
                    isError={error}
                    required={field?.requiredForNextStep}
                    callOnBlur={item_?.callOnBlur}
                  />
                );
              case 'checkboxColumn':
                return (
                  <RadioGroup
                    label={field?.label}
                    options={field?.options}
                    control={control}
                    name={field?.state}
                    underText={underText_}
                    isError={error}
                    required={field?.requiredForNextStep}
                    callOnBlur={item_?.callOnBlur}
                  />
                );
              case 'checkboxRow':
                return (
                  <RadioGroup
                    label={field?.label}
                    options={field?.options}
                    control={control}
                    name={field?.state}
                    isVertical={false}
                    underText={underText_}
                    isError={error}
                    required={field?.requiredForNextStep}
                    callOnBlur={item_?.callOnBlur}
                  />
                );
              case 'percentSlider':
                return (
                  <Controller
                    control={control}
                    name={field?.state}
                    render={({ field: field_ }) => (
                      <div className={`${error ? 'border border-red-500' : ''}`}>
                        {field?.label && <p className="mb-1 text-sm opacity-80">{field?.label}</p>}
                        <PercentSlider
                          rangeValues={[field_.value]}
                          // rangeValues={rangeValues}
                          setRangeValues={(range) => {
                            field_.onChange(range);
                            item_?.callOnBlur?.({ [field?.state]: range });
                          }}
                        />
                      </div>
                    )}
                  />
                );
              case 'imageUpload':
                return (
                  <Controller
                    control={control}
                    name={field?.state}
                    rules={{ required: field?.requiredForNextStep }}
                    render={({ field: _field }) => (
                      <Dropzone
                        text={field?.innerText}
                        label={field?.label}
                        underText={underText_}
                        isError={error}
                        smallImg={field?.smallImg}
                        selectFromModal={field?.selectFromModal}
                        filesInModal={field?.filesInModal}
                        favicon={field?.favicon}
                        aspect={field?.aspect}
                        imgPreview={
                          _field.value?.hostedUrl ? _field.value : { hostedUrl: _field.value }
                        }
                        multipleImgPreviews={_field.value}
                        handleDrop={(img) => {
                          console.log('THERE WAS A CHANGE', {
                            img,
                            _field,
                            CALLONBLURSKI: img.hostedUrl || cloudflareLink(img.cloudflare_uid),
                          });
                          if (field?.uploadMultiple) {
                            _field.onChange([
                              ..._field.value,
                              {
                                ...img,
                                hostedUrl: img?.hostedUrl || cloudflareLink(img.cloudflare_uid),
                              },
                            ]);
                          } else {
                            _field.onChange({
                              ...img,
                              hostedUrl:
                                img?.hostedUrl ||
                                _field?.value ||
                                cloudflareLink(img.cloudflare_uid),
                            });
                            item_?.callOnBlur?.({
                              [field?.state]:
                                img.hostedUrl ||
                                _field?.value ||
                                cloudflareLink(img.cloudflare_uid),
                            });
                          }
                        }}
                        onX={(i = null) => {
                          if (field?.uploadMultiple && typeof i === 'number') {
                            const temp = [..._field.value];
                            temp.splice(i, 1);
                            _field.onChange(temp);
                          } else {
                            _field.onChange('');
                            item_?.callOnBlur({ [field?.state]: '' });
                          }
                        }}
                        uploadMultiple={field?.uploadMultiple}
                        noCrop={field?.noCrop}
                      />
                    )}
                  />
                );
              case 'videoUpload':
                return (
                  <Controller
                    control={control}
                    name={field?.state}
                    rules={{ required: field?.requiredForNextStep }}
                    render={({ field: _field }) => (
                      <VideoUpload
                        withModalSelector
                        isError={!!error}
                        tokengated={!!field?.isTokengated}
                        uploadedVideo={_field.value}
                        setUploadedVideo={(img) => {
                          console.log({ img });
                          _field.onChange(img);
                        }}
                      />
                    )}
                  />
                );
              case 'phone':
                return (
                  <div>
                    {field?.label.length > 0 && (
                      <p className="mb-1 text-sm opacity-80">{field?.label}</p>
                    )}
                    <div
                      className={`relative rounded-[5px] border ${error ? 'border-red-500' : ''}`}
                    >
                      <Controller
                        control={control}
                        name={field?.state}
                        rules={{ required: field?.requiredForNextStep }}
                        render={({ field: _field }) => (
                          <div className="relative">
                            <PhoneInput
                              placeholder="Enter phone number"
                              value={field?.value}
                              onChange={(text) => {
                                console.log({ text });
                                _field.onChange(text);
                              }}
                              className={`px-[16px] py-[12px] text-sm`}
                              onBlur={() => {
                                console.log('PHONE BLUR');
                                item_?.callOnBlur({ [field?.state]: formValues[field?.state] });
                              }}
                            />
                          </div>
                        )}
                      />
                      <p
                        className={`absolute ml-[5px] mt-[1px] text-xs opacity-80 ${
                          error ? 'text-red-500' : ''
                        }`}
                      >
                        {underText_}
                      </p>
                    </div>
                  </div>
                );
              case 'color':
                return (
                  <div className="w-full">
                    {field?.label?.length > 0 && (
                      <p className="mb-1 text-sm opacity-80">{field?.label}</p>
                    )}
                    <Controller
                      control={control}
                      name={field?.state}
                      rules={{ required: field?.requiredForNextStep }}
                      render={({ field: _field }) => (
                        <div className="flex w-full flex-col">
                          <div className="flex w-full">
                            <div className="flex w-full rounded-[4px] border border-input bg-card p-[8px]">
                              <div
                                // eslint-disable-next-line tailwindcss/no-custom-classname
                                className="colorSquare"
                              >
                                <InputColor
                                  initialValue={_field?.value?.hex || `#5e72e4`}
                                  onChange={(color) => {
                                    if (
                                      color?.hex?.toLowerCase() ==
                                        _field?.value?.hex?.toLowerCase() ||
                                      !_field?.value?.hex
                                    ) {
                                      console.log('THEONCHANGE RETURN', {
                                        one: color?.hex,
                                        two: _field?.value?.hex,
                                      });
                                      return;
                                    }
                                    console.log('THEONCHANGE', { color });
                                    _field.onChange(color);
                                    item_?.callOnBlur?.({
                                      [field?.state]: color.hex,
                                    });
                                  }}
                                  placement="bottom"
                                  // formValues[field?.state]?.hex
                                />
                              </div>
                              <input
                                className="mr-[10px] w-full rounded-[4px] pl-[16px]"
                                placeholder="Enter Hex #"
                                value={`${formValues[field.state]?.hex}` || '#5e72e4'}
                                onBlur={() => {
                                  console.log(_field?.value);
                                  if (
                                    _field?.value?.hex.length > 6 &&
                                    _field?.value?.hex.includes('#')
                                  ) {
                                    item_?.callOnBlur?.({
                                      [field?.state]: _field?.value?.hex,
                                    });
                                  }
                                }}
                                onChange={(e) => {
                                  console.log(e);
                                  _field.onChange({ hex: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                );
              case 'location':
                return (
                  <Location label={field?.label} field={field} control={control} isError={error} />
                );

              default:
                return <div></div>;
            }
          })()}
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <form className="mx-auto flex w-full flex-col items-center">
      {data.map((item, index) => (
        <div key={`section_${index}`} className={cn('w-full', item.sectionClassName)}>
          {item?.title && (
            <h3 className="relative text-lg font-semibold">
              {item.title}
              {!!(item.hasToggle && item?.toggleState) && (
                <Controller
                  control={control}
                  name={item?.toggleState}
                  render={({ field }) => (
                    <Switch
                      key={field.value}
                      checked={field.value}
                      onCheckedChange={(bool) => {
                        field.onChange(bool);
                        item?.callOnBlur?.({ [item?.toggleState]: bool });
                      }}
                      className="absolute right-0"
                    />
                  )}
                />
              )}
            </h3>
          )}
          {item?.subTitle && <p className="mt-[5px]">{item?.subTitle}</p>}
          <div
            className={cn(
              'flex flex-wrap items-end justify-between gap-[22px]',
              item.sectionInputsClassName,
            )}
          >
            {item?.inputs?.map((item2, index2) => renderElement(item2, item, index2))}
            {item?.underSectionInputs?.length > 0 && (
              <div className={item?.underSectionClassName}>
                {item?.underSectionInputs?.map((item3, index3) =>
                  renderElement(item3, item, index3),
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </form>
  );
};

export default InputsSection;

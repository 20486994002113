import { useState } from 'react';

import Delete from '@/assets/Delete.svg?react';

const DeleteButton = ({ onClick = (e) => {} }) => {
  return (
    <button
      className="flex size-[48px] min-h-[48px] min-w-[48px] items-center justify-center rounded-full border bg-white"
      onClick={onClick}
      type="button"
    >
      <Delete className="stroke-primary" />
    </button>
  );
};

export { DeleteButton };

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import AddBold from '@/assets/AddBold.svg?react';
import Property from '@/assets/Property.svg?react';
import {
  GetRealEstateDocument,
  ListRealEstateDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import useBoundStore from '@/store';

import RealEstateCard from '../ui/real-estate-card';
import SectionDivider from '../ui/section-divider';

import 'react-horizontal-scrolling-menu/dist/styles.css';

const AllRealEstate: FunctionComponent<any> = () => {
  const navigate = useNavigate();

  // const [realEstate, setRealEstate] = useState([]);
  const { fetchRealEstate, initialLoad, realEstate } = useBoundStore(
    (state) => state.realEstateSlice,
  );

  // const handleListRealEstate = async () => {
  //   try {
  //     const {
  //       data: { listRealEstate: listRealEstateRes },
  //     } = await client.query({
  //       query: ListRealEstateDocument,
  //       variables: { active: false },
  //       context: {
  //         clientName: ClientName.Marketplace,
  //       },
  //     });
  //     console.log({ listRealEstateRes });
  //     setRealEstate(listRealEstateRes);
  //     setLoading(false);
  //   } catch (err) {
  //     console.log({ err });
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    // handleListRealEstate();
    fetchRealEstate();
  }, []);

  return (
    <div className="grid w-full max-w-[1300px] flex-col gap-[25px]">
      <SectionDivider
        titles={[`Property (${realEstate.length})`]}
        img={<Property stroke="#F9F9F9" />}
        color="#ff6661"
      />
      <div className="flex w-full flex-wrap gap-[20px] p-[5px]">
        <button
          className="box-border flex min-h-[340px] min-w-[244px] max-w-[244px] cursor-pointer items-center justify-center rounded-[20px] border-4 border-card bg-card shadow-md transition-colors duration-200 ease-in hover:bg-card-faded"
          onClick={() => {
            navigate('/create-real-estate');
          }}
        >
          <p className="ml-[-15px] flex font-semibold text-primary">
            <AddBold className="mr-3 stroke-primary" />
            Create Real Estate
          </p>
        </button>
        {initialLoad
          ? [1, 1, 1].map((item) => <RealEstateCard loading />)
          : realEstate.map((item, index) => (
              <RealEstateCard
                key={index}
                item={item}
                onClick={() => {
                  navigate(`/real-estate/${item.id}`);
                }}
              />
            ))}
      </div>
    </div>
  );
};

export default AllRealEstate;

/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Banking account number is a string of 5 to 17 alphanumeric values for representing an generic account number */
  AccountNumber: { input: any; output: any };
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: any; output: any };
  /** The `Byte` scalar type represents byte value as a Buffer */
  Byte: { input: any; output: any };
  /** A country code as defined by ISO 3166-1 alpha-2 */
  CountryCode: { input: any; output: any };
  /** A field whose value conforms to the standard cuid format as specified in https://github.com/ericelliott/cuid#broken-down */
  Cuid: { input: any; output: any };
  /** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
  Currency: { input: any; output: any };
  /** A field whose value conforms to the standard DID format as specified in did-core: https://www.w3.org/TR/did-core/. */
  DID: { input: any; output: any };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: { input: any; output: any };
  /** A field whose value conforms to the standard DeweyDecimal format as specified by the OCLC https://www.oclc.org/content/dam/oclc/dewey/resources/summaries/deweysummaries.pdf */
  DeweyDecimal: { input: any; output: any };
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  Duration: { input: any; output: any };
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: any; output: any };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  GUID: { input: any; output: any };
  /** A field whose value is a CSS HSL color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla(). */
  HSL: { input: any; output: any };
  /** A field whose value is a CSS HSLA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla(). */
  HSLA: { input: any; output: any };
  /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
  HexColorCode: { input: any; output: any };
  /** A field whose value is a hexadecimal: https://en.wikipedia.org/wiki/Hexadecimal. */
  Hexadecimal: { input: any; output: any };
  /** A field whose value is an International Bank Account Number (IBAN): https://en.wikipedia.org/wiki/International_Bank_Account_Number. */
  IBAN: { input: any; output: any };
  /** A field whose value is either an IPv4 or IPv6 address: https://en.wikipedia.org/wiki/IP_address. */
  IP: { input: any; output: any };
  /** A field whose value is an IPC Class Symbol within the International Patent Classification System: https://www.wipo.int/classifications/ipc/en/ */
  IPCPatent: { input: any; output: any };
  /** A field whose value is a IPv4 address: https://en.wikipedia.org/wiki/IPv4. */
  IPv4: { input: any; output: any };
  /** A field whose value is a IPv6 address: https://en.wikipedia.org/wiki/IPv6. */
  IPv6: { input: any; output: any };
  /** A field whose value is a ISBN-10 or ISBN-13 number: https://en.wikipedia.org/wiki/International_Standard_Book_Number. */
  ISBN: { input: any; output: any };
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  ISO8601Duration: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any };
  /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
  JWT: { input: any; output: any };
  /** A field whose value conforms to the Library of Congress Subclass Format ttps://www.loc.gov/catdir/cpso/lcco/ */
  LCCSubclass: { input: any; output: any };
  /** A field whose value is a valid decimal degrees latitude number (53.471): https://en.wikipedia.org/wiki/Latitude */
  Latitude: { input: any; output: any };
  /** A local date string (i.e., with no associated timezone) in `YYYY-MM-DD` format, e.g. `2020-01-01`. */
  LocalDate: { input: any; output: any };
  /** A local date-time string (i.e., with no associated timezone) in `YYYY-MM-DDTHH:mm:ss` format, e.g. `2020-01-01T00:00:00`. */
  LocalDateTime: { input: any; output: any };
  /** A local time string (i.e., with no associated timezone) in 24-hr `HH:mm[:ss[.SSS]]` format, e.g. `14:25` or `14:25:06` or `14:25:06.123`.  This scalar is very similar to the `LocalTime`, with the only difference being that `LocalEndTime` also allows `24:00` as a valid value to indicate midnight of the following day.  This is useful when using the scalar to represent the exclusive upper bound of a time block. */
  LocalEndTime: { input: any; output: any };
  /** A local time string (i.e., with no associated timezone) in 24-hr `HH:mm[:ss[.SSS]]` format, e.g. `14:25` or `14:25:06` or `14:25:06.123`. */
  LocalTime: { input: any; output: any };
  /** The locale in the format of a BCP 47 (RFC 5646) standard string */
  Locale: { input: any; output: any };
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  Long: { input: any; output: any };
  /** A field whose value is a valid decimal degrees longitude number (53.471): https://en.wikipedia.org/wiki/Longitude */
  Longitude: { input: any; output: any };
  /** A field whose value is a IEEE 802 48-bit MAC address: https://en.wikipedia.org/wiki/MAC_address. */
  MAC: { input: any; output: any };
  /** Floats that will have a value less than 0. */
  NegativeFloat: { input: any; output: any };
  /** Integers that will have a value less than 0. */
  NegativeInt: { input: any; output: any };
  /** A string that cannot be passed as an empty value */
  NonEmptyString: { input: any; output: any };
  /** Floats that will have a value of 0 or more. */
  NonNegativeFloat: { input: any; output: any };
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: { input: any; output: any };
  /** Floats that will have a value of 0 or less. */
  NonPositiveFloat: { input: any; output: any };
  /** Integers that will have a value of 0 or less. */
  NonPositiveInt: { input: any; output: any };
  /** A field whose value conforms with the standard mongodb object ID as described here: https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c */
  ObjectID: { input: any; output: any };
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: { input: any; output: any };
  /** A field whose value is a valid TCP port within the range of 0 to 65535: https://en.wikipedia.org/wiki/Transmission_Control_Protocol#TCP_ports */
  Port: { input: any; output: any };
  /** Floats that will have a value greater than 0. */
  PositiveFloat: { input: any; output: any };
  /** Integers that will have a value greater than 0. */
  PositiveInt: { input: any; output: any };
  /** A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg. */
  PostalCode: { input: any; output: any };
  /** A field whose value is a CSS RGB color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba(). */
  RGB: { input: any; output: any };
  /** A field whose value is a CSS RGBA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba(). */
  RGBA: { input: any; output: any };
  /** In the US, an ABA routing transit number (`ABA RTN`) is a nine-digit code to identify the financial institution. */
  RoutingNumber: { input: any; output: any };
  /** The `SafeInt` scalar type represents non-fractional signed whole numeric values that are considered safe as defined by the ECMAScript specification. */
  SafeInt: { input: any; output: any };
  /** A field whose value is a Semantic Version: https://semver.org */
  SemVer: { input: any; output: any };
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: { input: any; output: any };
  /** A field whose value exists in the standard IANA Time Zone Database: https://www.iana.org/time-zones */
  TimeZone: { input: any; output: any };
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: { input: any; output: any };
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  URL: { input: any; output: any };
  /** A currency string, such as $21.25 */
  USCurrency: { input: any; output: any };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: any; output: any };
  /** Floats that will have a value of 0 or more. */
  UnsignedFloat: { input: any; output: any };
  /** Integers that will have a value of 0 or more. */
  UnsignedInt: { input: any; output: any };
  /** A field whose value is a UTC Offset: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
  UtcOffset: { input: any; output: any };
  /** Represents NULL values */
  Void: { input: any; output: any };
};

export type AddInvitedUser = {
  __typename?: 'AddInvitedUser';
  error_joining_projects?: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  joined_projects?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type AddProjectInput = {
  app_name?: InputMaybe<Scalars['String']['input']>;
  blockchain?: InputMaybe<BlockchainType>;
  cluster_id?: InputMaybe<Scalars['Int']['input']>;
  encrypted_helper_secret?: InputMaybe<Scalars['String']['input']>;
  graphql_endpoint_url?: InputMaybe<Scalars['String']['input']>;
  pet_name?: InputMaybe<Scalars['String']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  project_name?: InputMaybe<Scalars['String']['input']>;
  react_urls?: InputMaybe<Scalars['String']['input']>;
  template_type?: InputMaybe<TemplateType>;
};

export type AddProjectUser = {
  access_level: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  family_name?: InputMaybe<Scalars['String']['input']>;
  gitlab_user_id?: InputMaybe<Scalars['ID']['input']>;
  given_name?: InputMaybe<Scalars['String']['input']>;
  issuer?: InputMaybe<Scalars['String']['input']>;
  needs_to_be_created: Scalars['Boolean']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AddUserInput = {
  finished_onboarding?: InputMaybe<Scalars['Boolean']['input']>;
  first_name: Scalars['String']['input'];
  is_dev_user?: InputMaybe<Scalars['Boolean']['input']>;
  last_name: Scalars['String']['input'];
  nickname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type AddUserToProjectInput = {
  access_level: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  project: Scalars['String']['input'];
};

export type ApiKeyInfo = {
  __typename?: 'ApiKeyInfo';
  created_at: Scalars['String']['output'];
  display_str: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  permissions: Scalars['JSON']['output'];
  updated_at: Scalars['String']['output'];
};

export type AssignProjectInput = {
  blockchain?: InputMaybe<BlockchainType>;
  display_name?: InputMaybe<Scalars['String']['input']>;
  logo_image_url?: InputMaybe<Scalars['String']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  template_type?: InputMaybe<TemplateType>;
};

export enum AuthType {
  Cognito = 'cognito',
  Magic = 'magic',
}

export type BatchAndPage = {
  batch_size?: InputMaybe<Scalars['Int']['input']>;
  page_number?: InputMaybe<Scalars['Int']['input']>;
};

export enum Blockchain {
  Ethereum = 'ethereum',
  Metis = 'metis',
  Solana = 'solana',
}

export enum BlockchainType {
  Ethereum = 'ethereum',
  Goerli = 'goerli',
  Metis = 'metis',
  Mumbai = 'mumbai',
  Polygon = 'polygon',
  Solana = 'solana',
}

export type BuildInformation = {
  __typename?: 'BuildInformation';
  db_connection_string: Scalars['String']['output'];
  graphql_current_build_image_version: Scalars['String']['output'];
  project_id: Scalars['String']['output'];
  project_name: Scalars['String']['output'];
  react_current_build_image_version: Scalars['String']['output'];
};

export type ChangeProjectAccessLevelInput = {
  new_level: Scalars['Int']['input'];
  project_id: Scalars['Int']['input'];
  user_id: Scalars['ID']['input'];
};

export type CheckGitlabUser = {
  __typename?: 'CheckGitlabUser';
  confirmed_at?: Maybe<Scalars['String']['output']>;
  current_sign_in_at?: Maybe<Scalars['String']['output']>;
  last_activity_on?: Maybe<Scalars['String']['output']>;
  last_sign_in_at?: Maybe<Scalars['String']['output']>;
  sign_in_count?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  two_factor_enabled?: Maybe<Scalars['Boolean']['output']>;
};

export type CheckInviteCancelledInput = {
  email: Scalars['String']['input'];
  project_id: Scalars['Int']['input'];
};

export type CheckUser = {
  __typename?: 'CheckUser';
  finished_onboarding?: Maybe<Scalars['Boolean']['output']>;
  has_project?: Maybe<Scalars['Boolean']['output']>;
  was_invited?: Maybe<Scalars['Boolean']['output']>;
};

export type CheckUserInput = {
  email: Scalars['String']['input'];
};

export type Company = {
  __typename?: 'Company';
  active: Scalars['Boolean']['output'];
  address?: Maybe<Scalars['String']['output']>;
  ceo_officer_id?: Maybe<Scalars['ID']['output']>;
  company_users?: Maybe<Array<Maybe<CompanyUser>>>;
  created_at: Scalars['String']['output'];
  date_of_organization?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  intellectual_property?: Maybe<Array<Maybe<IntellectualProperty>>>;
  legal_name: Scalars['String']['output'];
  legal_status?: Maybe<Scalars['String']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  officers?: Maybe<Array<Maybe<Officer>>>;
  phone?: Maybe<Scalars['String']['output']>;
  state_of_organization?: Maybe<Scalars['String']['output']>;
  tax_id?: Maybe<Scalars['String']['output']>;
  trademarks?: Maybe<Array<Maybe<Trademark>>>;
  twitter?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['String']['output'];
  website?: Maybe<Scalars['String']['output']>;
  whatsapp?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type CompanyInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  ceo?: InputMaybe<NameEmailPhoneInput>;
  ceo_officer_id?: InputMaybe<Scalars['ID']['input']>;
  date_of_organization?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  legal_name?: InputMaybe<Scalars['String']['input']>;
  legal_status?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  state_of_organization?: InputMaybe<Scalars['String']['input']>;
  tax_id?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  whatsapp?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyUser = {
  __typename?: 'CompanyUser';
  company_id: Scalars['ID']['output'];
  created_at: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  roles?: Maybe<Scalars['JSON']['output']>;
  updated_at: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
};

export type CreateApiKey = {
  __typename?: 'CreateApiKey';
  apikey: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  display_str: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  permissions: Scalars['JSON']['output'];
  updated_at: Scalars['String']['output'];
};

export type CreateApiKeyInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Scalars['JSON']['input']>;
};

export type CreateCompanyUserInput = {
  access_level: Scalars['Int']['input'];
  company_id: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProjectInput = {
  android_deploy: Scalars['Boolean']['input'];
  app_name: Scalars['String']['input'];
  company_id: Scalars['ID']['input'];
  ios_deploy: Scalars['Boolean']['input'];
  is_in_prod: Scalars['Boolean']['input'];
  use_blockchain: Scalars['Boolean']['input'];
  users?: InputMaybe<Array<InputMaybe<AddProjectUser>>>;
};

export type CreateProjectUserInput = {
  access_level: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  project_id: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  project_permissions?: InputMaybe<PermissionsInput>;
};

export type DeleteInviteInput = {
  email: Scalars['String']['input'];
  project_id: Scalars['Int']['input'];
};

export type DestroyProjectInput = {
  company_id: Scalars['ID']['input'];
  project_id: Scalars['ID']['input'];
};

export type GetMarketplaceApiPermissionsInput = {
  project: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type GetPermissionInput = {
  project: Scalars['String']['input'];
  user_id: Scalars['Int']['input'];
};

export type GetToken = {
  __typename?: 'GetToken';
  api_call_permisisons?: Maybe<Scalars['JSON']['output']>;
  token: Scalars['String']['output'];
};

export type GetUsersWithInputInput = {
  project_id?: InputMaybe<Scalars['Int']['input']>;
  search_string: Scalars['String']['input'];
};

export type ImagePresignedLinkInput = {
  file_extension?: InputMaybe<Scalars['String']['input']>;
  mime_type?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  private_image?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IntellectualProperty = {
  __typename?: 'IntellectualProperty';
  company_id: Scalars['ID']['output'];
  country: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  description_of_patent: Scalars['String']['output'];
  file_date: Scalars['String']['output'];
  grant_date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  patent: Scalars['String']['output'];
  patent_number: Scalars['String']['output'];
  updated_at: Scalars['String']['output'];
};

export type InviteUserInput = {
  access_level?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  family_name?: InputMaybe<Scalars['String']['input']>;
  given_name?: InputMaybe<Scalars['String']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type InviteUserToProjectInput = {
  access_level: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  family_name?: InputMaybe<Scalars['String']['input']>;
  given_name?: InputMaybe<Scalars['String']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addUserToProject?: Maybe<Scalars['Boolean']['output']>;
  assignProject?: Maybe<Scalars['Boolean']['output']>;
  changeProjectAccessLevel?: Maybe<Scalars['Boolean']['output']>;
  createApiKey?: Maybe<CreateApiKey>;
  createCompany?: Maybe<Company>;
  createUser?: Maybe<User>;
  deleteApiKeys?: Maybe<Scalars['Boolean']['output']>;
  deleteCompany?: Maybe<Scalars['Boolean']['output']>;
  deleteUser?: Maybe<User>;
  imagePresignedLink?: Maybe<Scalars['String']['output']>;
  pubsubPublishPublic?: Maybe<Scalars['Boolean']['output']>;
  setSelectedProject?: Maybe<Scalars['Boolean']['output']>;
  updateApiKey?: Maybe<ApiKeyInfo>;
  updateBuildInformation?: Maybe<Scalars['String']['output']>;
  updateCompany?: Maybe<Company>;
  updateDetails?: Maybe<Scalars['Boolean']['output']>;
  updatePermission?: Maybe<Scalars['Boolean']['output']>;
  updateUser?: Maybe<Scalars['Boolean']['output']>;
};

export type MutationAddUserToProjectArgs = {
  input: AddUserToProjectInput;
};

export type MutationAssignProjectArgs = {
  assignProjectInput?: InputMaybe<AssignProjectInput>;
};

export type MutationChangeProjectAccessLevelArgs = {
  input: ChangeProjectAccessLevelInput;
};

export type MutationCreateApiKeyArgs = {
  input?: InputMaybe<CreateApiKeyInput>;
};

export type MutationCreateCompanyArgs = {
  input: CompanyInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteApiKeysArgs = {
  ids: Array<Scalars['Int']['input']>;
};

export type MutationDeleteCompanyArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteUserArgs = {
  email: Scalars['String']['input'];
};

export type MutationImagePresignedLinkArgs = {
  input: ImagePresignedLinkInput;
};

export type MutationSetSelectedProjectArgs = {
  project: Scalars['String']['input'];
};

export type MutationUpdateApiKeyArgs = {
  input: UpdateApiKeyInput;
};

export type MutationUpdateBuildInformationArgs = {
  updateBuildInformationInput?: InputMaybe<UpdateBuildInformationInput>;
};

export type MutationUpdateCompanyArgs = {
  id: Scalars['Int']['input'];
  input: CompanyInput;
};

export type MutationUpdateDetailsArgs = {
  input?: InputMaybe<UpdateDetailsInput>;
};

export type MutationUpdatePermissionArgs = {
  input: UpdatePermissionInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type NameEmailPhoneInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type Officer = {
  __typename?: 'Officer';
  bio?: Maybe<Scalars['String']['output']>;
  birthplace?: Maybe<Scalars['String']['output']>;
  cell_phone?: Maybe<Scalars['String']['output']>;
  copy_of_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['String']['output'];
  dob?: Maybe<Scalars['String']['output']>;
  drivers_license_number?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employment_end_date?: Maybe<Scalars['String']['output']>;
  employment_start_date?: Maybe<Scalars['String']['output']>;
  first_date_in_company?: Maybe<Scalars['String']['output']>;
  full_legal_name?: Maybe<Scalars['String']['output']>;
  home_address?: Maybe<Scalars['String']['output']>;
  home_phone?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  id_document_number?: Maybe<Scalars['String']['output']>;
  id_expiration_date?: Maybe<Scalars['String']['output']>;
  id_issue_date?: Maybe<Scalars['String']['output']>;
  mothers_maiden_name?: Maybe<Scalars['String']['output']>;
  ownership_percentage?: Maybe<Scalars['String']['output']>;
  previous_employment?: Maybe<Scalars['String']['output']>;
  previous_role?: Maybe<Scalars['String']['output']>;
  responsibilities?: Maybe<Scalars['String']['output']>;
  social_security_number?: Maybe<Scalars['String']['output']>;
  state_or_country_of_issuance?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['String']['output'];
};

export type Permissions = {
  __typename?: 'Permissions';
  access_level: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['String']['output']>;
  project?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type PermissionsInput = {
  access_level: Scalars['Int']['input'];
  project: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Project = {
  __typename?: 'Project';
  app_name?: Maybe<Scalars['String']['output']>;
  blockchain?: Maybe<BlockchainType>;
  chain_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['String']['output']>;
  deleter_user_id?: Maybe<Scalars['String']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  encrypted_helper_secret?: Maybe<Scalars['String']['output']>;
  graphql_endpoint_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  logo_image_url?: Maybe<Scalars['String']['output']>;
  owner_user_id?: Maybe<Scalars['String']['output']>;
  pet_name?: Maybe<Scalars['String']['output']>;
  project_name?: Maybe<Scalars['String']['output']>;
  react_urls?: Maybe<Scalars['String']['output']>;
  template_type?: Maybe<TemplateType>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type ProjectInfo = {
  blockchain: BlockchainType;
  display_name: Scalars['String']['input'];
  logo_image_url?: InputMaybe<Scalars['String']['input']>;
  project_id: Scalars['Int']['input'];
};

export type ProjectInvite = {
  __typename?: 'ProjectInvite';
  access_level?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  family_name?: Maybe<Scalars['String']['output']>;
  given_name?: Maybe<Scalars['String']['output']>;
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  project_invite_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ProjectTeam = {
  email: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type ProjectUser = {
  __typename?: 'ProjectUser';
  access_level?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  deleter_user_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  is_deleted?: Maybe<Scalars['Boolean']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  time_of_deletion?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  getAllowedMethods?: Maybe<Scalars['JSON']['output']>;
  getApiKey?: Maybe<ApiKeyInfo>;
  getAvailableProjectCount?: Maybe<Scalars['Int']['output']>;
  getBuildInformationFromDB?: Maybe<Array<Maybe<BuildInformation>>>;
  getMarketplaceApiPermissions?: Maybe<Scalars['JSON']['output']>;
  getPermission?: Maybe<Permissions>;
  getProjectByID?: Maybe<Project>;
  getProjectTeam?: Maybe<Array<Maybe<TeamMember>>>;
  getProjectUsers?: Maybe<Array<Maybe<UserWithProjectPermissions>>>;
  getReactUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getSelectedProject?: Maybe<Scalars['String']['output']>;
  getToken: GetToken;
  getUser?: Maybe<User>;
  getUserCompanies?: Maybe<Array<Maybe<Company>>>;
  getUserProjects?: Maybe<Array<Maybe<Project>>>;
  isProjectOwner?: Maybe<Scalars['Boolean']['output']>;
  listApiKeys?: Maybe<Array<Maybe<ApiKeyInfo>>>;
  userExists: Scalars['Boolean']['output'];
};

export type QueryGetApiKeyArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetMarketplaceApiPermissionsArgs = {
  input: GetMarketplaceApiPermissionsInput;
};

export type QueryGetPermissionArgs = {
  input: GetPermissionInput;
};

export type QueryGetProjectByIdArgs = {
  project_id?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetProjectTeamArgs = {
  project_id: Scalars['Int']['input'];
};

export type QueryGetProjectUsersArgs = {
  project_id: Scalars['Int']['input'];
};

export type QueryGetReactUrlsArgs = {
  project_id?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryIsProjectOwnerArgs = {
  project_id: Scalars['Int']['input'];
};

export type QueryUserExistsArgs = {
  email: Scalars['String']['input'];
};

export type RemoveUserFromProjectInput = {
  project_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['ID']['input']>;
};

export type RestoreProject = {
  new_project_id: Scalars['ID']['input'];
  pet_name: Scalars['String']['input'];
};

export type RestoreProjectInput = {
  projects?: InputMaybe<Array<RestoreProject>>;
};

export type SaveOnboardingDataInput = {
  organization_details: Scalars['String']['input'];
  personal_details: Scalars['String']['input'];
};

export type SmallBatchAndPage = {
  batch_size?: InputMaybe<Scalars['Int']['input']>;
  page_number?: InputMaybe<Scalars['Int']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  project?: Maybe<SubscriptionEvent>;
  public?: Maybe<SubscriptionEvent>;
};

export type SubscriptionEvent = {
  __typename?: 'SubscriptionEvent';
  payload?: Maybe<Scalars['JSON']['output']>;
  type: SubscriptionEventType;
};

export enum SubscriptionEventType {
  AcceptOffer = 'accept_offer',
  Airdrop = 'airdrop',
  AirdropClaimed = 'airdrop_claimed',
  BidOnAuction = 'bid_on_auction',
  BusinessCardsDisabled = 'business_cards_disabled',
  BusinessCardsEnabled = 'business_cards_enabled',
  CancelAuction = 'cancel_auction',
  CancelListing = 'cancel_listing',
  CreateCollection = 'create_collection',
  CreateList = 'create_list',
  CreateNft = 'create_nft',
  CreateYourBusinessCard = 'create_your_business_card',
  DepositSucceeded = 'deposit_succeeded',
  EditBusinessCard = 'edit_business_card',
  EditNft = 'edit_nft',
  EndAuction = 'end_auction',
  ExportUsersUploaded = 'export_users_uploaded',
  KycFailed = 'kyc_failed',
  KycPassed = 'kyc_passed',
  LeaderboardDisabled = 'leaderboard_disabled',
  LeaderboardEnabled = 'leaderboard_enabled',
  LeaderboardUpdated = 'leaderboard_updated',
  ListedNft = 'listed_nft',
  MakeOffer = 'make_offer',
  MintNft = 'mint_nft',
  NewBusinessCard = 'new_business_card',
  OutbidOnAuction = 'outbid_on_auction',
  Purchase = 'purchase',
  RescindOffer = 'rescind_offer',
  StartAuction = 'start_auction',
  TestEvent = 'test_event',
  TransferNft = 'transfer_nft',
  UpdateNotification = 'update_notification',
  UserEventPassCreated = 'user_event_pass_created',
  UtilityCreated = 'utility_created',
  VideoProcessed = 'video_processed',
  VideoProcessedFailed = 'video_processed_failed',
  WithdrawFailed = 'withdraw_failed',
  WithdrawInitiated = 'withdraw_initiated',
  WithdrawSucceeded = 'withdraw_succeeded',
}

export type TeamMember = {
  __typename?: 'TeamMember';
  email?: Maybe<Scalars['String']['output']>;
  has_accepted_invite?: Maybe<Scalars['Boolean']['output']>;
  is_owner?: Maybe<Scalars['Boolean']['output']>;
};

export enum TemplateType {
  BaseApp = 'base_app',
  Marketplace = 'marketplace',
  NftDropSite = 'nft_drop_site',
}

export type Trademark = {
  __typename?: 'Trademark';
  company_id: Scalars['ID']['output'];
  country: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  grant_date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  serial_number: Scalars['String']['output'];
  trademark: Scalars['String']['output'];
  updated_at: Scalars['String']['output'];
};

export type TransferProjectOwnershipInput = {
  new_owner_issuer?: InputMaybe<Scalars['String']['input']>;
  old_owner_issuer?: InputMaybe<Scalars['String']['input']>;
  project_id?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateAboutYourselfInput = {
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  personal_details?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateApiKeyInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateBuildInformationInput = {
  graphql_current_build_image_version: Scalars['String']['input'];
  project_name: Scalars['String']['input'];
  react_current_build_image_version: Scalars['String']['input'];
};

export type UpdateDetailsInput = {
  curr_display_name: Scalars['String']['input'];
  project_info: ProjectInfo;
  project_team: Array<InputMaybe<ProjectTeam>>;
  user_info: UserInfo;
};

export type UpdatePermissionInput = {
  access_level?: InputMaybe<Scalars['Int']['input']>;
  project: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  user_id: Scalars['Int']['input'];
};

export type UpdateUserInput = {
  finished_onboarding?: InputMaybe<Scalars['Boolean']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  tos_accepted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type User = {
  __typename?: 'User';
  created_at?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  finished_onboarding?: Maybe<Scalars['Boolean']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  public_key?: Maybe<Scalars['String']['output']>;
  tos_accepted?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  wallet_address?: Maybe<Scalars['String']['output']>;
};

export type UserInfo = {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  organization_details?: InputMaybe<Scalars['String']['input']>;
  personal_details?: InputMaybe<Scalars['String']['input']>;
};

export type UserProject = {
  __typename?: 'UserProject';
  access_level?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  project_id?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type UserWithProjectPermissions = {
  __typename?: 'UserWithProjectPermissions';
  created_at?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  finished_onboarding?: Maybe<Scalars['Boolean']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Permissions>;
  phone?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  public_key?: Maybe<Scalars['String']['output']>;
  tos_accepted?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  wallet_address?: Maybe<Scalars['String']['output']>;
};

export type WasCreated = {
  __typename?: 'WasCreated';
  first_name?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  was_created: Scalars['Boolean']['output'];
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser?: { __typename?: 'User'; id?: string | null } | null;
};

export type UpdateUserTosAcceptedMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserTosAcceptedMutation = {
  __typename?: 'Mutation';
  updateUser?: boolean | null;
};

export type ImagePresignedLinkMutationVariables = Exact<{
  input: ImagePresignedLinkInput;
}>;

export type ImagePresignedLinkMutation = {
  __typename?: 'Mutation';
  imagePresignedLink?: string | null;
};

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  input: CompanyInput;
}>;

export type UpdateCompanyMutation = {
  __typename?: 'Mutation';
  updateCompany?: { __typename?: 'Company'; id: string } | null;
};

export type SetSelectedProjectMutationVariables = Exact<{
  project: Scalars['String']['input'];
}>;

export type SetSelectedProjectMutation = {
  __typename?: 'Mutation';
  setSelectedProject?: boolean | null;
};

export type CreateApiKeyMutationVariables = Exact<{
  input?: InputMaybe<CreateApiKeyInput>;
}>;

export type CreateApiKeyMutation = {
  __typename?: 'Mutation';
  createApiKey?: {
    __typename?: 'CreateApiKey';
    id: number;
    display_str: string;
    name: string;
    permissions: any;
    created_at: string;
    updated_at: string;
    apikey: string;
  } | null;
};

export type UpdatePermissionMutationVariables = Exact<{
  input: UpdatePermissionInput;
}>;

export type UpdatePermissionMutation = {
  __typename?: 'Mutation';
  updatePermission?: boolean | null;
};

export type AddUserToProjectMutationVariables = Exact<{
  input: AddUserToProjectInput;
}>;

export type AddUserToProjectMutation = {
  __typename?: 'Mutation';
  addUserToProject?: boolean | null;
};

export type GetTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetTokenQuery = {
  __typename?: 'Query';
  getToken: { __typename?: 'GetToken'; token: string; api_call_permisisons?: any | null };
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: 'Query';
  getUser?: {
    __typename?: 'User';
    id?: string | null;
    email: string;
    first_name?: string | null;
    last_name?: string | null;
    phone?: string | null;
    picture?: string | null;
    wallet_address?: string | null;
    public_key?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    finished_onboarding?: boolean | null;
    tos_accepted?: boolean | null;
  } | null;
};

export type UserExistsQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type UserExistsQuery = { __typename?: 'Query'; userExists: boolean };

export type GetUserCompaniesAllDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserCompaniesAllDataQuery = {
  __typename?: 'Query';
  getUserCompanies?: Array<{
    __typename?: 'Company';
    id: string;
    legal_name: string;
    legal_status?: string | null;
    state_of_organization?: string | null;
    address?: string | null;
    date_of_organization?: string | null;
    tax_id?: string | null;
    website?: string | null;
    phone?: string | null;
    logo?: string | null;
    facebook?: string | null;
    instagram?: string | null;
    whatsapp?: string | null;
    twitter?: string | null;
    linkedin?: string | null;
    youtube?: string | null;
    active: boolean;
    created_at: string;
    updated_at: string;
    officers?: Array<{
      __typename?: 'Officer';
      id: string;
      email?: string | null;
      full_legal_name?: string | null;
      title?: string | null;
      responsibilities?: string | null;
      first_date_in_company?: string | null;
      previous_employment?: string | null;
      bio?: string | null;
      previous_role?: string | null;
      employment_start_date?: string | null;
      employment_end_date?: string | null;
      home_address?: string | null;
      dob?: string | null;
      home_phone?: string | null;
      cell_phone?: string | null;
      social_security_number?: string | null;
      drivers_license_number?: string | null;
      id_document_number?: string | null;
      state_or_country_of_issuance?: string | null;
      id_issue_date?: string | null;
      id_expiration_date?: string | null;
      copy_of_id?: string | null;
      ownership_percentage?: string | null;
      birthplace?: string | null;
      mothers_maiden_name?: string | null;
      created_at: string;
      updated_at: string;
    } | null> | null;
    company_users?: Array<{
      __typename?: 'CompanyUser';
      id: string;
      company_id: string;
      user_id: string;
      roles?: any | null;
      created_at: string;
      updated_at: string;
    } | null> | null;
    intellectual_property?: Array<{
      __typename?: 'IntellectualProperty';
      id: string;
      company_id: string;
      patent: string;
      patent_number: string;
      description_of_patent: string;
      file_date: string;
      grant_date: string;
      country: string;
      created_at: string;
      updated_at: string;
    } | null> | null;
    trademarks?: Array<{
      __typename?: 'Trademark';
      id: string;
      company_id: string;
      trademark: string;
      serial_number: string;
      grant_date: string;
      country: string;
      created_at: string;
      updated_at: string;
    } | null> | null;
  } | null> | null;
};

export type GetUserProjects_MinimalQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserProjects_MinimalQuery = {
  __typename?: 'Query';
  getUserProjects?: Array<{
    __typename?: 'Project';
    graphql_endpoint_url?: string | null;
    chain_id: number;
    id?: number | null;
    display_name?: string | null;
    logo_image_url?: string | null;
    react_urls?: string | null;
    pet_name?: string | null;
    project_name?: string | null;
    created_at?: string | null;
  } | null> | null;
};

export type GetSelectedProjectQueryVariables = Exact<{ [key: string]: never }>;

export type GetSelectedProjectQuery = { __typename?: 'Query'; getSelectedProject?: string | null };

export type ListApiKeysQueryVariables = Exact<{ [key: string]: never }>;

export type ListApiKeysQuery = {
  __typename?: 'Query';
  listApiKeys?: Array<{
    __typename?: 'ApiKeyInfo';
    display_str: string;
    id: number;
    name: string;
    permissions: any;
    created_at: string;
    updated_at: string;
  } | null> | null;
};

export type GetProjectUsersQueryVariables = Exact<{
  project_id: Scalars['Int']['input'];
}>;

export type GetProjectUsersQuery = {
  __typename?: 'Query';
  getProjectUsers?: Array<{
    __typename?: 'UserWithProjectPermissions';
    id?: string | null;
    email: string;
    permissions?: {
      __typename?: 'Permissions';
      title?: string | null;
      access_level: number;
    } | null;
  } | null> | null;
};

export const CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserTosAcceptedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserTosAccepted' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserTosAcceptedMutation, UpdateUserTosAcceptedMutationVariables>;
export const ImagePresignedLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'imagePresignedLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ImagePresignedLinkInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imagePresignedLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImagePresignedLinkMutation, ImagePresignedLinkMutationVariables>;
export const UpdateCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CompanyInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const SetSelectedProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetSelectedProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'project' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setSelectedProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'project' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetSelectedProjectMutation, SetSelectedProjectMutationVariables>;
export const CreateApiKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createApiKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateApiKeyInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createApiKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_str' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'apikey' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateApiKeyMutation, CreateApiKeyMutationVariables>;
export const UpdatePermissionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePermission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePermissionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePermission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePermissionMutation, UpdatePermissionMutationVariables>;
export const AddUserToProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addUserToProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AddUserToProjectInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUserToProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddUserToProjectMutation, AddUserToProjectMutationVariables>;
export const GetTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetToken' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getToken' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'api_call_permisisons' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTokenQuery, GetTokenQueryVariables>;
export const GetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'first_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'last_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'picture' } },
                { kind: 'Field', name: { kind: 'Name', value: 'wallet_address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'public_key' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'finished_onboarding' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tos_accepted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const UserExistsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserExists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userExists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserExistsQuery, UserExistsQueryVariables>;
export const GetUserCompaniesAllDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserCompaniesAllData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserCompanies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legal_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legal_status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state_of_organization' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date_of_organization' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tax_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'facebook' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
                { kind: 'Field', name: { kind: 'Name', value: 'whatsapp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkedin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'youtube' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'officers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'full_legal_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'responsibilities' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'first_date_in_company' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previous_employment' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bio' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previous_role' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'employment_start_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'employment_end_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'home_address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dob' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'home_phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cell_phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'social_security_number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'drivers_license_number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id_document_number' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'state_or_country_of_issuance' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id_issue_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id_expiration_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'copy_of_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ownership_percentage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthplace' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mothers_maiden_name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'company_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'intellectual_property' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'company_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'patent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'patent_number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description_of_patent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'file_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'grant_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trademarks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'company_id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'trademark' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'serial_number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'grant_date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserCompaniesAllDataQuery, GetUserCompaniesAllDataQueryVariables>;
export const GetUserProjects_MinimalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserProjects_Minimal' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserProjects' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'graphql_endpoint_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'chain_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo_image_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'react_urls' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pet_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'react_urls' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserProjects_MinimalQuery, GetUserProjects_MinimalQueryVariables>;
export const GetSelectedProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSelectedProject' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'getSelectedProject' } }],
      },
    },
  ],
} as unknown as DocumentNode<GetSelectedProjectQuery, GetSelectedProjectQueryVariables>;
export const ListApiKeysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listApiKeys' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listApiKeys' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'display_str' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'display_str' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListApiKeysQuery, ListApiKeysQueryVariables>;
export const GetProjectUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProjectUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'project_id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProjectUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'project_id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'project_id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'access_level' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectUsersQuery, GetProjectUsersQueryVariables>;

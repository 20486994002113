import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { SelectorDropdown } from '@/components/ui/selector-dropdown';
import { useToast } from '@/components/ui/use-toast';
import { AccessLevel, permissionNames } from '@/const';
import { UpdatePermissionDocument } from '@/lib/__generated__/dashboard/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';

const TeamCard: FunctionComponent<Props> = ({
  item,
  create = false,
  onChange = (arg) => {},
  isLoading = false,
  onSelect = (arg) => {},
  update = false,
}) => {
  const decodedToken = useBoundStore((state) => state.authSlice.decodedToken);
  const selectedProject = useBoundStore((state) => state.projectSlice.selectedProject);
  const { toast } = useToast();
  const isSuperUser = decodedToken?.user?.super_user;
  const my_access_level = decodedToken?.projects?.[selectedProject?.project_name]?.access_level;

  const [selectedPermission, setSelectedPermission] = useState<number | null>(
    item?.permissions?.access_level ?? AccessLevel.CREATE,
  );

  const permissionsOptions = [
    { label: 'No Permission', value: 0 },
    { label: 'Observer', value: 10 },
    { label: 'Viewer', value: 20 },
    { label: 'Lister', value: 25 },
    { label: 'Editor', value: 30 },
    { label: 'Creator', value: 40 },
    { label: 'Admin', value: 50 },
    { label: 'Owner', value: 60 },
  ];

  const handleGetProjectTeamRes = async (selectedPerm) => {
    try {
      const {
        data: { updatePermission: updatePermissionRes },
      } = await client.mutate({
        mutation: UpdatePermissionDocument,
        variables: {
          input: {
            user_id: parseInt(item?.id || ''),
            title: permissionNames[selectedPerm],
            project: selectedProject.project_name,
            access_level: selectedPerm,
          },
        },
        context: { clientName: ClientName.Dashboard },
      });
      console.log({ updatePermissionRes });
    } catch (error) {
      console.log({ error });
      const err = error as Error;
      toast({
        title: 'Error',
        variant: 'destructive',
        description: `${err.message}`,
      });
    }
  };

  const handleSelectPermission = (_item) => {
    if (selectedPermission) {
      setSelectedPermission(parseInt(_item));
      if (create) {
        onSelect(parseInt(_item));
      }
      if (update) {
        handleGetProjectTeamRes(_item);
      }
    }
  };

  // useEffect(() => {
  //   console.log({ selectedPermission });
  //   setSelectedPermission(item?.permissions?.access_level);
  // }, [selectedPermission]);

  return (
    <div className="flex h-[52px] w-[49%] items-center overflow-hidden rounded-[10px] border bg-card">
      {isLoading ? (
        <div className="flex w-full justify-between">
          <div className="text-md ml-[24px] h-[26px] w-[40%] animate-pulse rounded-[6px] pl-[20px]" />
        </div>
      ) : (
        <>
          <input
            className={cn(
              'text-md ml-[4px] h-[30px] h-[42px] w-full rounded-[6px] pl-[20px]',
              create ? 'rounded-md border' : '',
            )}
            value={item?.email}
            onChange={(e) => {
              onChange(e);
            }}
          />
          {/* <p>
            {selectedPermission} || {item?.permissions?.access_level}
          </p> */}
          <div className="mr-[8px] w-[170px]" key={item?.email}>
            <SelectorDropdown
              label={''}
              name={'_item'}
              selected={selectedPermission}
              options={permissionsOptions}
              underText={''}
              isError={false}
              required={false}
              callOnBlur={({ _item }) => {
                console.log({ _item, my_access_level }, _item);
                handleSelectPermission(_item);
              }}
              className="m-[4px] h-[42px] w-[170px] text-base"
              disabled={!isSuperUser && my_access_level < 50}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TeamCard;

type Props = {
  item?: {
    id?: string;
    email: string;
    permissions?: {
      access_level?: number;
    };
  };
  create?: boolean;
  onChange?: (arg) => void;
  isLoading?: boolean;
  onSelect?: (arg) => void;
  selected?: null | number;
  update?: boolean;
};

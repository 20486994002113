import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { ProgressCard } from '@/components/ui/progress-card';
import { Select, SelectTrigger, SelectValue } from '@/components/ui/select';
import { SelectorDropdown } from '@/components/ui/selector-dropdown';
import { Table } from '@/components/ui/table';

export function Fundraise() {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);

  const tabs = ['Draft / Pending', 'Live', 'Closed', 'Declined'];

  const columnNames = ['Round Name', 'Raise Amount (USD) ', 'Regulation', 'Time', 'Status'];

  const mockColumns = [
    [
      { value: 'Seed', type: '' },
      { value: '$5,000,000.00', type: '' },
      { value: 'RegCF.pdf', type: 'link', link: '' },
      { value: '3 days ago', type: '' },
      { value: 'Draft', type: 'status' },
    ],
  ];

  const progressCards = [
    { progress: 50, title: 'LEGAL CHECKLIST', navigateTo: 'legal-checklist' },
    { progress: 80, title: 'Broker Dealer Checklist', navigateTo: '' },
    {
      progress: 30,
      title: 'Transfer Agent Checklist',
      navigateTo: 'transfer-agent-checklist',
    },
    { progress: 20, title: 'Site Builder', navigateTo: 'site-builder' },
  ];

  return (
    <div className="flex w-full max-w-[1000px] flex-col pb-[60px]">
      <div>
        <img
          className="h-[200px] w-full rounded-b-[20px] border bg-card"
          src="https://picsum.photos/1000/200"
        />
      </div>
      <img
        className="mx-auto mt-[-70px] size-[160px] rounded-[80px] border shadow"
        src="https://picsum.photos/200"
      />
      <h1 className="mb-[54px] mt-[32px] text-center text-[2rem]">AB Company</h1>
      <div className="mx-auto w-full max-w-[1200px] px-[10px] sm:px-[20px]">
        <div className="mb-[20px] w-full overflow-x-scroll sm:mb-[48px] md:overflow-x-visible">
          <div className="relative flex w-full min-w-[600px] border-b-2 border-gray-500 border-opacity-40 pb-[10px] md:w-auto">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`box-border w-1/4 py-0 text-base font-medium uppercase text-gray-500 ${
                  activeTab === index ? '!text-blue-500' : ''
                }`}
                onClick={() => {
                  setActiveTab(index);
                }}
              >
                {tab}
              </button>
            ))}
            <div
              className="absolute bottom-0 left-0 mb-[-2px] h-[2px] w-1/4 bg-blue-500 transition-all duration-500 ease-in-out"
              style={{ left: `${activeTab * 25}%` }}
            ></div>
          </div>
        </div>
        <div className="mb-[20px] rounded-[8px] bg-card shadow-[2px_2px_24px_0px_rgba(0,0,0,0.05)] sm:mb-[50px]">
          <div className="border-b-[#2D344E] pb-[16px] pl-[24px] pt-[24px]">
            {/* <Select>
              <SelectTrigger className="w-[300px]">
                <SelectValue placeholder="Filter" />
              </SelectTrigger>
            </Select> */}
            <SelectorDropdown
              name={'projectSelect'}
              label={''}
              selected={''}
              options={[{ label: 'Selector', value: 'val' }]}
              underText={''}
              isError={false}
              required={false}
              className="h-[42px] w-[250px] rounded-[6px] bg-card text-base"
            />
          </div>
          <div className="overflow-x-scroll border-t py-[8px] pb-[16px] sm:overflow-x-auto">
            <Table
              ariaLabel="fundraiseTable"
              data={mockColumns}
              columnNames={columnNames}
              selected={0}
            />
          </div>
        </div>
        <ProgressCard
          progress={40}
          centeredTitle="Selected Fundraise Overall Progress"
          bgColor="#606EFF26"
          seeTasks={false}
        />
        <div className="mt-[20px] grid gap-[20px] sm:mt-[40px] sm:grid-cols-2 sm:gap-[40px]">
          {progressCards.map((item, index) => (
            <ProgressCard
              key={index}
              rightTitle={index == 0 ? 'We recommend starting here' : ''}
              leftTitle={item.title}
              progress={item.progress}
              tasksClick={() => {
                navigate(`/${item.navigateTo}`);
              }}
              bgColor={'bg-[#880808]'}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Fundraise;

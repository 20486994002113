import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import spacetime from 'spacetime';

import SectionDivider from '@/components/ui/section-divider';
import { SelectorDropdown } from '@/components/ui/selector-dropdown';
import { zeroAddress } from '@/const';
import {
  GetAllNftsByCollectionDocument,
  GetCollectionAnalyticsDocument,
  GetCollectionEventsDocument,
  GetMarketplaceAnalyticsDocument,
  GetNftAnalyticsDocument,
  GetNftEventsDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import useBoundStore from '@/store';
import { BarChartIcon, DownloadIcon } from '@radix-ui/react-icons';

import ActivityView from '../../components/ui/activity-view';
import LinearChart from '../ui/linear-chart';

const Analytics: FunctionComponent = () => {
  const collections = useBoundStore((state) => state.collectionsSlice.collections);
  const selectedProject = useBoundStore((state) => state.projectSlice.selectedProject);
  const navigate = useNavigate();

  const [batchEventsLoading, setBatchEventsLoading] = useState(true);
  const [batchEvents, setBatchEvents] = useState([]);

  const [collectionEventsLoading, setCollectionEventsLoading] = useState(true);
  const [collectionEvents, setCollectionEvents] = useState([]);

  const [batchesLoading, setBatchesLoading] = useState(true);
  const [batches, setBatches] = useState<BatchType[]>([]);

  const [selectedBatch, setSelectedBatch] = useState(0);
  const [selectedCollection, setSelectedCollection] = useState(0);

  const [selectedCollectionDate, setSelectedCollectionDate] = useState(0);
  const [selectedAssetDate, setSelectedAssetDate] = useState(0);

  const [marketplaceAnalyticsLoading, setMarketplaceAnalyticsLoading] = useState(true);
  const [marketplaceAnalytics, setMarketplaceAnalytics] = useState({
    collections: 0,
    num_minted: 0,
    nft_owners: 0,
    max_supply: 0,
    num_batches: 0,
    number_of_nft_owners: 0,
    sales: 0,
  });
  const [collectionAnalyticsLoading, setCollectionAnalyticsLoading] = useState(true);
  const [collectionAnalytics, setCollectionAnalytics] = useState<CollectionAnalyticsProps>({
    favorites: '',
    num_minted: 0,
    max_supply: 0,
    sales: 0,
    transactions: 0,
    price_data_points: [],
    view_count: 0,
  });

  const [batchAnalyticsLoading, setBatchAnalyticsLoading] = useState(true);
  const [batchAnalytics, setBatchAnalytics] = useState<BatchAnalyticsProps>({
    favorites: '',
    sales: 0,
    lowest_price: 0,
    max_supply: 0,
    highest_price: 0,
    num_minted: 0,
    transactions: 0,
    view_count: 0,
    price_data_points: [],
  });

  const now = spacetime.now();

  const sortByDatesList = [
    { time: now.subtract(7, 'days').format('MM-DD-YYYY') },
    { time: now.subtract(1, 'month').format('MM-DD-YYYY') },
    { time: now.subtract(1, 'year').format('MM-DD-YYYY') },
    { time: spacetime(parseInt(selectedProject?.created_at)).format('MM-DD-YYYY') },
  ];

  const analyticsSquares = [
    {
      label: 'Total Assets',
      value: marketplaceAnalytics?.num_batches,
    },
    { label: 'Total Assets Minted', value: marketplaceAnalytics?.num_minted },
    {
      label: 'Total sales',
      value: `$${(marketplaceAnalytics?.sales / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    },
    { label: 'Total collections', value: marketplaceAnalytics?.collections },
    {
      label: 'Total Asset owners',
      value: marketplaceAnalytics?.number_of_nft_owners,
      onClick: () => {
        // setOpenModal(true);
        // setChooseNFT(false);
      },
    },
  ];

  const collectionSquares = [
    { label: 'Views', value: collectionAnalytics?.view_count },
    { label: 'Total Sales ', value: `$${collectionAnalytics.sales / 100}`, className: 'greenTxt' },
    { label: 'Assets', value: collectionAnalytics.max_supply || 0 },
    { label: 'Favorites', value: collectionAnalytics.favorites },
    { label: 'Minted Assets', value: collectionAnalytics.num_minted },
    { label: 'Transactions', value: collectionAnalytics.transactions },
  ];

  const nftSquares = [
    { label: 'Total Sales ', value: `$${batchAnalytics.sales / 100}`, className: 'greenTxt' },
    {
      label: 'Highest Price',
      value: `$${batchAnalytics.highest_price / 100}`,
      className: 'blueTxt',
    },
    { label: 'Lowest price', value: `$${batchAnalytics.lowest_price / 100}`, className: 'redTxt' },
    { label: '# of Assets', value: `${batchAnalytics.max_supply}` },
    { label: '# Minted ', value: `${batchAnalytics.num_minted}` },
    { label: 'Transactions', value: `${batchAnalytics.transactions}` },
  ];

  const collectionsList = useMemo(
    () =>
      collections?.map((item, index) => {
        if (item.contract_address === null) return;
        else return { label: item.name, value: index };
      }),
    [collections],
  );

  const handleGetMarketplaceAnalytics = async () => {
    try {
      const {
        data: { getMarketplaceAnalytics: getMarketplaceAnalyticsRes },
      } = await client.query({
        query: GetMarketplaceAnalyticsDocument,
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      setMarketplaceAnalytics(getMarketplaceAnalyticsRes);
      setMarketplaceAnalyticsLoading(false);
      console.log({ getMarketplaceAnalyticsRes });
    } catch (err) {
      console.log({ err });
    }
  };

  const handleGetCollectionAnalytics = async () => {
    try {
      setCollectionAnalyticsLoading(true);
      setCollectionEventsLoading(true);
      const {
        data: { getCollectionAnalytics: getCollectionAnalyticsRes },
      } = await client.query({
        query: GetCollectionAnalyticsDocument,
        variables: {
          input: {
            collection_contract_address: collections[selectedCollection]?.contract_address,
            start_date: sortByDatesList[selectedCollectionDate].time,
            end_date: spacetime().add(1, 'day').format('MM-DD-YYYY'),
          },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getCollectionAnalyticsRes });
      setCollectionAnalyticsLoading(false);
      setCollectionAnalytics({
        ...getCollectionAnalyticsRes,
        price_data_points: getCollectionAnalyticsRes.price_data_points.map((item) => ({
          ...item,
          _day: spacetime(item._day).format('{month} {date} {year}'),
        })),
      });
      const {
        data: { getCollectionEvents: getCollectionEventsRes },
      } = await client.query({
        query: GetCollectionEventsDocument,
        variables: {
          input: {
            collection_contract_address: collections[selectedCollection]?.contract_address,
            event_types: ['Mint'],
          },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getCollectionEventsRes });
      setCollectionEvents(getCollectionEventsRes);
      setCollectionEventsLoading(false);
    } catch (err) {
      console.log({ err });
    }
  };

  const handleGetNftEvents = async () => {
    try {
      setBatchEventsLoading(true);
      const {
        data: { getNftEvents: getNftEventsRes },
      } = await client.query({
        query: GetNftEventsDocument,
        variables: {
          input: {
            nft_id: batches[selectedBatch]?.batch_id,
            event_types: ['Mint'],
          },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });

      const filteredOutIgnored =
        getNftEventsRes.filter((act) => {
          if (act.event_type !== 'TransferSingle') return true;
          return act.ui_from && act.ui_from !== zeroAddress;
        }) ?? [];

      console.log({ getNftEventsRes, filteredOutIgnored });

      setBatchEvents(filteredOutIgnored);
      setBatchEventsLoading(false);
    } catch (getEventsFromBatchIdErr) {
      console.log({ getEventsFromBatchIdErr });
    }
  };

  const handleGetAllBatchesByCollectionId = async () => {
    try {
      setBatchesLoading(true);
      const {
        data: { getAllNftsByCollection: getAllNftsByCollectionRes },
      } = await client.query({
        query: GetAllNftsByCollectionDocument,
        variables: {
          input: {
            collection_contract_address: collections[selectedCollection]?.contract_address,
            batch_size: 30,
            page_number: 0,
          },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getAllNftsByCollectionRes });
      setBatches(getAllNftsByCollectionRes);
      setBatchesLoading(false);
    } catch (getAllBatchesByCollectionIdErr) {
      console.log({ getAllBatchesByCollectionIdErr });
    }
  };

  const handleGetBatchAnalytics = async () => {
    try {
      setBatchAnalyticsLoading(true);
      const {
        data: { getNftAnalytics: getNftAnalyticsRes },
      } = await client.query({
        query: GetNftAnalyticsDocument,
        variables: {
          input: {
            nft_id: batches[selectedBatch]?.batch_id,
            start_date: sortByDatesList[selectedAssetDate].time,
            end_date: spacetime().add(1, 'day').format('MM-DD-YYYY'),
          },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getNftAnalyticsRes });
      setBatchAnalyticsLoading(false);
      setBatchAnalytics({
        ...getNftAnalyticsRes,
        price_data_points: getNftAnalyticsRes.price_data_points.map((item) => ({
          ...item,
          _day: spacetime(item._day).format('{year}-{month}-{date-pad}'),
        })),
      });
    } catch (getBatchAnalyticsErr) {
      console.log({ getBatchAnalyticsErr });
    }
  };

  const collectionPricePoints = useMemo(() => {
    return (
      collectionAnalytics?.price_data_points?.map((item) => ({
        ...item,
        total_sales: parseInt(item.total_sales),
      })) || []
    );
  }, [collectionAnalytics]);

  const batchPricePoints = useMemo(() => {
    return batchAnalytics?.price_data_points?.map((item) => ({
      ...item,
      total_sales: parseInt(item.total_sales),
    }));
  }, [batchAnalytics]);

  useEffect(() => {
    handleGetMarketplaceAnalytics();
  }, []);

  useEffect(() => {
    if (collections.length > 0) {
      handleGetAllBatchesByCollectionId();
    }
  }, [selectedCollection, collections]);

  useEffect(() => {
    if (collections.length > 0) {
      handleGetCollectionAnalytics();
    }
  }, [selectedCollection, collections, selectedCollectionDate]);

  useEffect(() => {
    if (batches.length > 0) {
      handleGetBatchAnalytics();
      handleGetNftEvents();
    }
  }, [batches, selectedBatch, selectedAssetDate]);

  useEffect(() => {
    console.log({});
  }, [selectedProject]);

  return (
    <div className="grid w-full max-w-[1100px] flex-col gap-[25px]">
      <SectionDivider
        titles={['Analytics']}
        img={<BarChartIcon className="stroke-white" stroke="white" />}
        color="#9256FF"
      />
      <div className="flex gap-[20px] rounded-[16px] bg-card p-[20px] shadow-md">
        {analyticsSquares.map(({ label, value, onClick = false }) => (
          <div
            key={label}
            className="relative flex w-full flex-col items-center rounded-[8px] border border-input py-[8px] shadow-sm"
            // onClick={onClick}
          >
            <p className="text-sm opacity-50">{label}</p>
            {!marketplaceAnalyticsLoading ? (
              <h3 className="text-2xl">{value}</h3>
            ) : (
              <div className="my-[4px] h-[24px] w-[100px] animate-pulse rounded-[6px]" />
            )}
            {/* {onClick && (
              <p className="absolute bottom-0 right-0 mb-[3px] mr-[6px] text-sm font-semibold text-primary">
                View
              </p>
            )} */}
          </div>
        ))}
      </div>
      <div className="rounded-[16px] bg-card p-[25px] shadow-md">
        <h3 className="m-0 text-[18px] font-normal">Collections Analytics</h3>
        <div className="w-full border-b border-input pt-[20px]" />
        <div className="flex p-[20px]">
          <div className="box-border min-w-[500px] pr-[30px]">
            <SelectorDropdown
              name={'screenSelect'}
              label={''}
              selected={
                selectedCollection === null
                  ? 'Select a collection' // REVISIT
                  : collectionsList[selectedCollection]?.value
              }
              options={collectionsList || []}
              underText={''}
              isError={false}
              required={false}
              callOnBlur={({ screenSelect }, index) => {
                console.log({ screenSelect });
                setSelectedCollection(screenSelect);
              }}
              className="h-[42px] w-full rounded-[5px] bg-card text-base"
            />
            <div className="mb-[15px] mt-[10px] flex items-end justify-between">
              {!collectionAnalyticsLoading ? (
                <p className="dateText text-sm">
                  {`Date Created:  ${spacetime(collections[selectedCollection]?.created_at / 1).format('{month} {date} {year}')}`}
                </p>
              ) : (
                <div className="my-[1px] h-[18px] w-[220px] animate-pulse rounded-[6px]" />
              )}
              <DownloadIcon className="size-[22px]" />
            </div>

            <div className="flex justify-between">
              {collectionAnalyticsLoading ? (
                <div className="size-[92px] animate-pulse rounded-[8px]" />
              ) : (
                <img
                  className="h-[92px] max-w-[185px] rounded-[8px] shadow-[3.61026px_3.61026px_5.41538px_rgba(0,0,0,0.25)]"
                  src={collections[selectedCollection]?.cover_image}
                />
              )}
              <button
                onClick={() => {
                  navigate(`/collection/${collections[selectedCollection]?.contract_address}`);
                }}
                className="h-[40px] w-[150px] rounded-[4px] bg-primary text-sm font-semibold text-white"
              >
                View Collection
              </button>
            </div>
            <div className="mt-[20px]">
              {collectionAnalyticsLoading ? (
                <div className="flex h-[247px] w-full items-center justify-center rounded-[10px] border">
                  <div className="size-8 animate-spin rounded-full border-t-2 border-primary" />
                </div>
              ) : (
                <LinearChart
                  useMoment={false}
                  priceKey={'total_sales'}
                  dateKey={'_day'}
                  xAxisLabel={''}
                  data={collectionPricePoints}
                  loading={false}
                  total={`$${collectionAnalytics.sales / 100}`}
                  title={'Collection sales'}
                  selectedDate={selectedCollectionDate}
                  setSelectedDate={setSelectedCollectionDate}
                />
              )}
            </div>
          </div>
          <div className="right flex w-full flex-col">
            <div className="mb-[20px] grid grid-cols-3 gap-[15px]">
              {collectionSquares.map(({ label, value, className = '' }, index) => (
                <div
                  key={`collection_${index}`}
                  className={`flex h-[71px] flex-col items-center justify-center rounded-[7px] border border-input`}
                >
                  <p className="text-center text-sm">{label}</p>
                  {!collectionAnalyticsLoading ? (
                    <h3 className={`${className} text-[18px] font-semibold`}>{value}</h3>
                  ) : (
                    <div className="my-[3px] h-[21px] w-[50px] animate-pulse rounded-[6px]" />
                  )}
                </div>
              ))}
            </div>
            <ActivityView
              className="max-h-[192px] min-h-[192px] overflow-y-scroll"
              events={collectionEvents}
              loading={collectionEventsLoading}
            />
          </div>
        </div>
      </div>
      <div className="rounded-[16px] bg-card p-6 shadow-md">
        <h3 className="m-0 text-[18px] font-normal">Asset Analytics</h3>
        <div className="w-full border-b border-input pt-5" />
        <div className="w-full">
          <div className=" flex py-4">
            <SelectorDropdown
              name={'screenSelect'}
              label={''}
              selected={
                selectedCollection === null
                  ? 'Select a collection' // REVISIT
                  : collectionsList[selectedCollection]?.value
              }
              options={collectionsList || []}
              underText={''}
              isError={false}
              required={false}
              callOnBlur={({ screenSelect }, index) => {
                console.log({ screenSelect });
                setSelectedCollection(screenSelect);
              }}
              className="h-[42px] w-full rounded-[5px] bg-card text-base"
            />
          </div>
          <div className="flex rounded-[16px] border border-input px-4 py-5">
            <div className="relative mb-2.5 flex h-[250px] w-full flex-wrap overflow-y-scroll p-[0px]">
              {batchesLoading
                ? [1, 1, 1, 1, 1, 1, 1].map((item, index) => (
                    <div
                      key={`nft_square_loading-${index}`}
                      className={`mx-[10px] mb-3 w-[90px] cursor-pointer rounded-[8px]`}
                    >
                      <div
                        className={cn(
                          'size-[90px] animate-pulse rounded-[8px] shadow-[2px_2px_12px_rgba(6,6,6,0.15)]',
                        )}
                      />
                    </div>
                  ))
                : batches.map((item, index) => (
                    <div
                      key={`nft_squaer-${index}`}
                      className={`mx-[10px] mb-3 w-[90px] cursor-pointer rounded-[8px]`}
                      onClick={() => {
                        setSelectedBatch(index);
                      }}
                    >
                      <img
                        src={item?.media_ipfs_link}
                        className={cn(
                          'size-[90px] rounded-[8px] shadow-[2px_2px_12px_rgba(6,6,6,0.15)]',
                          selectedBatch == index ? 'border-2 border-primary' : '',
                        )}
                      />
                      <p className="truncate text-[14px] text-[#2D344E]">{item?.name}</p>
                    </div>
                  ))}
            </div>
          </div>
          {batches.length > 0 && (
            <div className="flex py-5">
              <div className="box-border min-w-[500px] flex-1 pr-7">
                <div className="flex items-end justify-between">
                  {batchAnalyticsLoading ? (
                    <div className="mr-5 h-[185px] min-w-[185px] animate-pulse rounded-[8px] border border-input shadow-[3.61026px_3.61026px_5.41538px_rgba(0,0,0,0.25)]" />
                  ) : (
                    <img
                      className="mr-5 h-[185px] min-w-[185px] rounded-[8px] border border-input shadow-[3.61026px_3.61026px_5.41538px_rgba(0,0,0,0.25)]"
                      src={batches[selectedBatch]?.media_ipfs_link}
                    />
                  )}
                  <div className="flex h-[185px] w-full flex-col">
                    <div className="flex justify-between">
                      {batchAnalyticsLoading ? (
                        <div className="mb-[4px] h-[20px] w-[150px] animate-pulse rounded-[6px] text-[14px]" />
                      ) : (
                        <h3 className="font-medium">{batches[selectedBatch]?.name}</h3>
                      )}
                      {/* Icon component can be placed here */}
                    </div>
                    {batchAnalyticsLoading ? (
                      <div className="mb-4 mt-1 h-[21px] w-[200px] animate-pulse rounded-[6px] text-[14px]" />
                    ) : (
                      <p className="mb-4 mt-1 text-[14px]">
                        Collection: {collections[selectedCollection]?.name}
                      </p>
                    )}
                    <button
                      className="text-text-color mb-2.5 ml-auto h-[40px] w-[150px] rounded-[4px] bg-primary text-[14px] font-semibold"
                      onClick={() => {
                        navigate(`/asset/${batches[selectedBatch].batch_id}`);
                      }}
                    >
                      View Asset
                    </button>
                    <div className="flex">
                      <div className="mr-2.5 flex w-1/2 items-center justify-center rounded-[5px] border border-input p-1">
                        {batchAnalyticsLoading ? (
                          <div className="my-[2px] h-[20px] w-[50px] animate-pulse rounded-[6px]" />
                        ) : (
                          <p>{batchAnalytics.view_count} views</p>
                        )}
                      </div>
                      <div className="flex w-1/2 items-center justify-center rounded-[5px] border border-input p-1">
                        {batchAnalyticsLoading ? (
                          <div className="my-[2px] h-[20px] w-[50px] animate-pulse rounded-[6px]" />
                        ) : (
                          <p>{batchAnalytics.favorites} likes</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-[20px]">
                  <LinearChart
                    useMoment={false}
                    priceKey={'total_sales'}
                    dateKey={'_day'}
                    xAxisLabel={''}
                    data={batchPricePoints}
                    loading={false}
                    total={`$${batchAnalytics.sales / 100}`}
                    title={'Asset sales'}
                    selectedDate={selectedAssetDate}
                    setSelectedDate={setSelectedAssetDate}
                  />
                </div>
              </div>
              <div className="flex flex-1 flex-col">
                <div className="my-5 grid grid-cols-3 gap-[10px]">
                  {nftSquares.map(({ label, value, className = '' }, index) => (
                    <div
                      key={`nft_analytic_${index}`}
                      className={`flex h-[71px] flex-col items-center justify-center rounded-[7px] border border-input ${className}`}
                    >
                      <p className="text-[14px]">{label}</p>

                      {!batchAnalyticsLoading ? (
                        <h3 className="text-[18px] font-semibold">{value}</h3>
                      ) : (
                        <div className="my-[3px] h-[21px] w-[50px] animate-pulse rounded-[6px]" />
                      )}
                    </div>
                  ))}
                </div>
                <ActivityView
                  className="max-h-[180px] min-h-[180px] overflow-y-scroll"
                  events={batchEvents}
                  loading={batchEventsLoading}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Analytics;

type ItemActivityObj = {
  _day: string;
  total_sales: string;
};

type BatchType = {
  batch_id: string;
  media_ipfs_link: string;
  name: string;
  collection_name?: string;
};

type BatchAnalyticsProps = {
  favorites: string;
  sales: number;
  lowest_price: number;
  max_supply: number;
  highest_price: number;
  num_minted: number;
  transactions: number;
  view_count: number;
  price_data_points: ItemActivityObj[];
};

type CollectionAnalyticsProps = {
  view_count: number;
  sales: number;
  max_supply: number;
  favorites: string;
  num_minted: number;
  transactions: number;
  price_data_points: ItemActivityObj[];
};

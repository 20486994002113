import { useEffect, useMemo, useState } from 'react';
import {
  Cell,
  Column,
  Row,
  Table as AriaTable,
  TableBody,
  TableHeader,
} from 'react-aria-components';

import { cn } from '@/lib/utils';

interface DataValue {
  value: string;
  type?: string;
  link?: string;
  className?: string;
}

type DataRow = DataValue[];

interface TableProps {
  data?: DataRow[];
  columnNames?: string[];
  selected?: number | null;
  className?: string;
  isLoading?: boolean;
  onRowClick?: any;
  ariaLabel: string;
  noDataText?: string;
}

export const Table: React.FC<TableProps> = ({
  data = [],
  columnNames = [],
  selected = null,
  className = '',
  isLoading = false,
  onRowClick = (item, index) => {},
  ariaLabel = '',
  noDataText = 'No Data',
}) => {
  const [showNoData, setShowNoData] = useState(false);

  const renderClassname = (type) => {
    switch (type) {
      case 'link':
        return 'text-blue-500';
      case 'status':
        return 'bg-[#6b8cef33] text-center rounded-[5px] text-xs font-medium py-[4px]';
      default:
        return ``;
    }
  };

  const loadingColumnsArray = columnNames.map(() => ({
    value: <div className="h-[24px] w-full animate-pulse rounded-[6px]" />,
  }));

  const renderData = useMemo(() => {
    if (isLoading) {
      return [1, 1, 1, 1, 1].map((item) => loadingColumnsArray);
    } else {
      return data;
    }
  }, [data, isLoading]);

  useEffect(() => {
    console.log({ isLoading, data });
    if (!isLoading && data.length == 0) {
      setShowNoData(true);
    } else {
      setShowNoData(false);
    }
  }, [isLoading, data]);

  return (
    <div key={`${showNoData}`} className={className}>
      {showNoData ? (
        <div className="flex h-[232px] w-full items-center justify-center rounded-[10px] bg-card pb-[20px] pr-[10px] pt-[10px]">
          <p className="opacity-60">{noDataText}</p>
        </div>
      ) : (
        <AriaTable className={cn('w-full min-w-[800px] sm:min-w-full')} aria-label={ariaLabel}>
          <TableHeader>
            {columnNames.map((item, index) => (
              <Column
                key={index}
                isRowHeader={index == 0}
                className="px-4 py-2 pl-[24px] text-left text-xs font-normal text-text-color opacity-50"
              >
                {item}
              </Column>
            ))}
          </TableHeader>
          <TableBody>
            {renderData?.map((item, index) => (
              <Row
                className={
                  'cursor-pointer border-b border-input transition-colors duration-100 ease-in hover:bg-card-faded'
                }
                key={`row_${index}`}
              >
                {item?.map((item2, index2) => (
                  <Cell
                    key={index2}
                    style={{ backgroundColor: index == selected ? '#606EFF26' : '' }}
                    className={cn('text-left text-sm font-light ', item2?.className)}
                  >
                    <div
                      className="w-full px-4 py-2 pl-[24px]"
                      onClick={() => {
                        onRowClick(item, index);
                      }}
                    >
                      <p className={renderClassname(item2?.type)}>{item2?.value}</p>
                    </div>
                  </Cell>
                ))}
              </Row>
            ))}
          </TableBody>
        </AriaTable>
      )}
    </div>
  );
};

export default Table;

import { useEffect, useMemo } from 'react';

export default function RoyaltyProgressBar({
  royalties,
  royaltyType,
  label = 'On Mint',
  totalMintRoyalties,
  unassigned = 'Unassigned',
}) {
  const typeColors = {
    mint_royalties: [
      '#9256FF',
      '#8067F9',
      '#6E78F3',
      '#5C89ED',
      '#4A9AE7',
      '#38ABE1',
      '#26BCDB',
      '#14CDD5',
      '#00DACC',
    ],
    secondary_royalties: [
      '#FFBD39',
      '#FAC508',
      '#F9B707',
      '#F8A906',
      '#F79B05',
      '#F68D04',
      '#F57F03',
      '#F47102',
      '#FF6661',
    ],
  };

  const sortedRoyalties = useMemo(() => {
    return royalties.sort((i, j) => j.mint_royalties - i.mint_royalties);
  }, [royalties]);

  const totalMintRoyaltiesLeft = useMemo(() => {
    return 100 - totalMintRoyalties(royaltyType);
  }, [totalMintRoyalties]);

  return (
    <div className="rounded-2xl border bg-card px-[40px] py-[30px] pb-[70px]" onClick={() => {}}>
      <p className="mb-[15px]">{`${label} Royalties`}</p>
      <div className="flex h-[36px] w-full bg-[#f3f3f3]">
        {sortedRoyalties.map(
          (r, index) =>
            r[royaltyType] > 0 && (
              <div
                className={`relative ml-[-1px] h-full min-w-[100px] border-l`}
                style={{
                  width: `${parseFloat(r[royaltyType])}%`,
                  backgroundColor: typeColors[royaltyType][index],
                }}
                key={index}
              >
                <div className="absolute bottom-0 z-10 mb-[-45px] flex w-full flex-col items-center">
                  <p className=" flex items-center text-xs">
                    <div
                      className="mr-2 size-[10px] rounded-full"
                      style={{ backgroundColor: typeColors[royaltyType][index] }}
                    />
                    {r.name}
                  </p>
                  <p className="ml-[18px] mt-[3px] text-center text-sm font-bold">{`${parseFloat(
                    r[royaltyType],
                  )}%`}</p>
                </div>
              </div>
            ),
        )}
        {totalMintRoyaltiesLeft !== 0 && (
          <div
            className="relative w-full border bg-[#f3f3f3]"
            style={{ width: `${totalMintRoyaltiesLeft}%` }}
          >
            <div className="absolute bottom-0 z-10 mb-[-45px] flex w-full flex-col items-center">
              <p className=" flex items-center text-xs">
                <div className="mr-2 size-[10px] rounded-full" />
                {unassigned}
              </p>
              <p className="ml-[18px] mt-[3px] text-center text-sm font-bold">{`${totalMintRoyaltiesLeft}%`}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

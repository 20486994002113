import { useCallback, useEffect, useState } from 'react';

import { Progress } from '@/components/ui/progress';
import { cn } from '@/lib/utils';

import ArrowRight from '../../assets/ArrowRight.svg?react';
import Help from '../../assets/Help.svg?react';

export function ProgressCard({
  progress = 0,
  tasksClick = () => {},
  bgColor = '',
  leftTitle = '',
  rightTitle = '',
  centeredTitle = '',
  seeTasks = true,
}) {
  return (
    <div
      className={cn(
        'rounded-[16px] bg-card p-[24px] shadow-[2px_2px_24px_0px_rgba(0,0,0,0.1)] ',
        `bg-[${bgColor}]`,
      )}
      style={{ backgroundColor: `${bgColor}` }}
    >
      {centeredTitle.length > 0 && (
        <p className="mb-[32px]  text-center text-lg font-medium uppercase sm:text-2xl">
          {centeredTitle}
        </p>
      )}
      {(leftTitle.length > 0 || rightTitle.length > 0) && (
        <div className="mb-[16px] flex justify-between">
          {leftTitle.length > 0 && (
            <p className="flex items-center text-sm font-semibold uppercase opacity-50">
              {leftTitle} <Help className="ml-1 h-[17px] w-[17px] stroke-[#000000]" />
            </p>
          )}
          {rightTitle.length > 0 && (
            <p className="text-sm uppercase text-[#FFBD39]">{rightTitle}</p>
          )}
        </div>
      )}
      <Progress value={progress} />
      <div className="mb-[24px] mt-[16px] flex justify-between">
        <p className="flex items-center text-sm">
          <div className="mr-[10px] h-[10px] w-[10px] rounded-[10px] bg-primary" />
          <span className="opacity-70">Complete</span>{' '}
          <strong className="ml-[10px] text-sm">{progress}%</strong>
        </p>
        <p className="text-sm font-semibold">5/6</p>
      </div>
      {seeTasks && (
        <button
          className="flex cursor-pointer text-sm text-[#FFBD39] hover:underline"
          onClick={tasksClick}
        >
          See All Tasks <ArrowRight className="stroke-[#FFBD39]" />
        </button>
      )}
    </div>
  );
}

export default ProgressCard;

import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { withLenses } from '@dhmk/zustand-lens';

import { MyAssetsSlice, myAssetsSlice as assetsSlice } from './assets-slice';
import { AuthSlice, authSlice } from './auth-slice';
import { MyCollectionsSlice, myCollectionsSlice as collectionsSlice } from './collections-slice';
import { CompanySlice, companySlice } from './company-slice';
import { FilesSlice, filesSlice } from './files-slice';
import { ListsAndRewardsSlice, listsAndRewardsSlice } from './lists-and-rewards-slice';
import { PayoutProgramsSlice, payoutProgramsSlice } from './payout-programs-slice';
import { ProjectSlice, projectSlice } from './project-slice';
import { RealEstateSlice, realEstateSlice } from './real-estate-slice';
import { ReloadSlice, reloadSlice } from './reload-slice';
import { UsersSlice, usersSlice } from './users-slice';

// Log every time state is changed
const log = (config) => (set, get, api) =>
  config(
    (...args) => {
      const { type, ...payload } = args[2] ?? {};
      console.groupCollapsed('#########', type ?? args[2], payload);
      set(...args);
      // console.debug('payload', payload);
      console.debug('new state', get());
      console.groupEnd();
    },
    get,
    api,
  );
// TODO: add persist
const useBoundStore = create(
  devtools(
    log(
      withLenses({
        authSlice,
        companySlice,
        projectSlice,
        usersSlice,
        listsAndRewardsSlice,
        filesSlice,
        collectionsSlice,
        assetsSlice,
        realEstateSlice,
        payoutProgramsSlice,
        reloadSlice,
      }),
    ),
  ),
);

export interface StoreType {
  authSlice: AuthSlice;
  companySlice: CompanySlice;
  projectSlice: ProjectSlice;
  usersSlice: UsersSlice;
  listsAndRewardsSlice: ListsAndRewardsSlice;
  filesSlice: FilesSlice;
  reloadSlice: ReloadSlice;
  collectionsSlice: MyCollectionsSlice;
  assetsSlice: MyAssetsSlice;
  realEstateSlice: RealEstateSlice;
  payoutProgramsSlice: PayoutProgramsSlice;
}

export default useBoundStore;

import { useState } from 'react';

import Add from '@/assets/Add.svg?react';

const AddButton = ({ onClick = (e) => {} }) => {
  return (
    <button
      className="flex size-[48px] min-h-[48px] min-w-[48px] items-center justify-center rounded-full border bg-white"
      onClick={onClick}
      type="button"
    >
      <Add className="stroke-primary" />
    </button>
  );
};

export { AddButton };

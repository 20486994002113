import React from 'react';

import { ProgressCard } from '@/components/ui/progress-card';

function LegalChecklist() {
  const progressCards = [
    { progress: 50, title: 'General Information', navigateTo: '' },
    { progress: 20, title: 'My Team', navigateTo: '' },
    { progress: 80, title: 'Capital Raise', navigateTo: '' },
    {
      progress: 30,
      title: 'Business details',
      navigateTo: 'transfer-agent-checklist',
    },
    { progress: 100, title: 'Past Raises', navigateTo: '' },
    { progress: 90, title: 'Financials', navigateTo: '' },
    { progress: 20, title: 'Use of Funds', navigateTo: '' },
    { progress: 20, title: 'My documents ', navigateTo: '' },
  ];

  return (
    <div className="flex flex-col items-center">
      <div className="my-[70px] flex w-full max-w-[80%] flex-col items-center justify-center gap-[40px] lg:w-[820px]">
        <h1 className="text-center  text-xl  sm:text-3xl">Legal Checklist</h1>
        <p className="text-center text-2xl font-light">
          In order to raise capital it is necessary to file the appropriate forms with the SEC. The
          Legal Checklist will walk you through the process and collect the required information
          along the way.
        </p>
      </div>
      <div className="mt-[20px] grid w-full max-w-[1296px] gap-[20px] px-[30px] md:mt-[40px] md:grid-cols-2 md:gap-[40px]">
        {progressCards.map((item, index) => (
          <ProgressCard
            key={index}
            rightTitle={index == 0 ? 'We recommend starting here' : ''}
            leftTitle={item.title}
            progress={item.progress}
            tasksClick={() => {
              // navigate(`/${item.navigateTo}`);
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default LegalChecklist;

import { useEffect, useMemo, useRef, useState } from 'react';
import { isAddress } from 'ethereum-address';
import { Controller, useFormContext } from 'react-hook-form';

import Delete from '@/assets/Delete.svg?react';
import Edit from '@/assets/Edit.svg?react';
import { Input } from '@/components/ui/input';

export default function RoyaltyInput({
  otherRoyalties = [],
  totalMintRoyalties,
  royaltyType,
  item = {},
  index = 0,
  state,
  addUserBtn = '+ ADD USER',
  setMintErr,
  mintErr,
  setSecondaryErr,
  secondaryErr,
  validateInputs,
  setValidWalletAddresses,
  validWalletAddresses,
}: any) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [open, setOpen] = useState(false);
  const [name, setName] = useState(item.username || `User-${index + 1}`);
  // const [validWalletAddress, setValidWalletAddress] = useState(true);

  const totalMintRoyaltiesLeft = useMemo(() => {
    return 100 - totalMintRoyalties('mint_royalties');
  }, [totalMintRoyalties]);

  const totalSecondaryRoyaltiesLeft = useMemo(() => {
    return 100 - totalMintRoyalties('secondary_royalties');
  }, [totalMintRoyalties]);

  const getTotalRoyalties = (type, currentRoyalties) => {
    let total = 0;
    [...otherRoyalties, ...currentRoyalties].forEach((user) => {
      total += user[type] ? parseInt(user[type], 10) : 0;
    });
    return total;
  };

  const handleInputRoyalty = (e, _field, index, type, setErr, totalpercentage) => {
    const inputValue = e.target.value;
    if (
      inputValue.length <= 5 &&
      inputValue.split('.').length - 1 < 2 &&
      (inputValue === '' || !isNaN(parseFloat(inputValue)))
    ) {
      console.log({ inputValue }, !isNaN(parseFloat(inputValue)));

      const copy = [..._field.value];
      const newRoyalty = inputValue === '' ? '' : inputValue;
      copy[index][type] = newRoyalty;
      setErr('');
      _field.onChange(copy);
      const total = getTotalRoyalties(type, copy);
      if (total > totalpercentage) {
        setErr(
          `All ${
            type == 'mint_royalties' ? 'mint' : 'secondary'
          } royalties must total to ${totalpercentage}`,
        );
      } else {
        setErr('');
      }
    }
  };

  return (
    <Controller
      control={control}
      name={state}
      rules={{ validate: (value) => validateInputs() || 'ErOR' }}
      render={({ field: _field }) => (
        <div className="flex flex-col gap-[20px]">
          {_field.value?.map((item, index) => (
            <div
              key={index}
              className="box-border flex w-full flex-col justify-between gap-[15px] rounded-xl border bg-card p-6"
            >
              {royaltyType == 'payout_receivers' ? (
                <>
                  <div className="flex items-center leading-none">
                    {/* {index !== 0 && (
                      <button
                        className="flex h-[30px] w-[30px] items-center justify-center rounded-full border bg-card"
                        onClick={() => {
                          setOpen(!open);
                        }}
                      >
                        <Edit stroke="rgba(69, 85, 255, 1)" />
                      </button>
                    )} */}
                    {index == 0 ? (
                      <h4 className="text-md mr-2.5 font-semibold">
                        {index !== 0 ? name : 'Owner'}
                        <span className="ml-2.5 text-sm font-normal opacity-60">
                          {index == 0 ? ' (Credit card applicant, if applicable)' : ''}{' '}
                        </span>
                      </h4>
                    ) : (
                      // }
                      // {open && (
                      <Input
                        className="w-68 ml-1.25 text-md h-8 rounded border pl-2.5 font-semibold"
                        placeholder="Enter User Name"
                        value={item.displayName}
                        onChange={(e) => {
                          const copy = [..._field.value];
                          copy[index].displayName = e.target.value;
                          _field.onChange([...copy]);
                        }}
                      />
                    )}
                    {index !== 0 && (
                      <button
                        className="ml-[10px] flex size-[30px] items-center justify-center rounded-full border"
                        onClick={() => {
                          const copy = [..._field.value];
                          copy.splice(index, 1);
                          _field.onChange(copy);
                        }}
                      >
                        <Delete className="h-5 stroke-[#2D344E]" />
                      </button>
                    )}
                  </div>
                  <div className="flex justify-evenly">
                    <Input
                      label="Email or Wallet Address"
                      placeholder="Enter wallet address"
                      width="100%"
                      value={item.wallet_address}
                      onChange={(e) => {
                        const copy = [..._field.value];
                        copy[index].wallet_address = e.target.value;
                        _field.onChange([...copy]);
                      }}
                      isError={!validWalletAddresses[index]}
                      underText={
                        !validWalletAddresses[index] ? 'Email or Wallet Address invalid' : ''
                      }
                      callOnBlur={() => {
                        const addresses = [...validWalletAddresses];
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        addresses[index] =
                          isAddress(item.wallet_address) || emailRegex.test(item.wallet_address);
                        setValidWalletAddresses(addresses);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className="flex items-center justify-between leading-none">
                  <h4 className="mr-2.5">{`Referral Tier ${index + 1} `}</h4>
                  {index !== 0 && (
                    <button
                      className="ml-[10px] flex size-[30px] items-center justify-center rounded-full border"
                      onClick={() => {
                        const copy = [..._field.value];
                        copy.splice(index, 1);
                        _field.onChange(copy);
                      }}
                    >
                      <Delete className="h-5 stroke-[#2D344E]" />
                    </button>
                  )}
                </div>
              )}

              <div className="flex justify-evenly">
                <Input
                  label="On Mint Royalties (in %)"
                  placeholder="e.g. 2.5%"
                  value={item.mint_royalties}
                  isError={mintErr.length > 0}
                  underText={mintErr}
                  className="w-[98%]"
                  onChange={(e) => {
                    handleInputRoyalty(e, _field, index, 'mint_royalties', setMintErr, 100);
                  }}
                />
                <Input
                  label="Secondary Royalties (in %)"
                  placeholder="e.g. 2.5%"
                  value={item.secondary_royalties}
                  isError={secondaryErr.length > 0}
                  underText={secondaryErr}
                  className="w-[98%]"
                  onChange={(e) => {
                    handleInputRoyalty(
                      e,
                      _field,
                      index,
                      'secondary_royalties',
                      setSecondaryErr,
                      20,
                    );
                  }}
                />
              </div>
            </div>
          ))}
          {_field.value.length < 5 && (
            <div className="py-3.75 flex w-full items-end justify-end">
              <button
                className="flex h-[48px]  w-full items-center justify-center rounded-xl border bg-card p-2.5 text-sm font-semibold uppercase text-primary"
                onClick={() => {
                  if (_field.value.length <= 0) {
                    _field.onChange([
                      {
                        name: `user ${_field.value.length + 1}`,
                        displayName:
                          royaltyType == 'payout_receivers'
                            ? `user ${_field.value.length + 1}`
                            : '',
                        username: '',
                        wallet_address: '',
                        mint_royalties: '',
                        secondary_royalties: '',
                      },
                    ]);
                    return;
                  }
                  const copy = [..._field.value];
                  _field.onChange([
                    ...copy,
                    {
                      name: `user ${_field.value.length + 1}`,
                      displayName:
                        royaltyType == 'payout_receivers' ? `user ${_field.value.length + 1}` : '',
                      username: '',
                      wallet_address: '',
                      mint_royalties: '',
                      secondary_royalties: '',
                    },
                  ]);
                }}
              >
                {addUserBtn}
              </button>
            </div>
          )}
        </div>
      )}
    />
  );
}

import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import spacetime from 'spacetime';

import DefaultProfileImage from '@/assets/Default_profile_bold.svg';
import MembershipBenefits from '@/assets/membershipBenefits.svg?react';
import NFTImg from '@/assets/NFTImg.svg?react';
import OverviewAssets from '@/assets/overviewAssets.svg?react';
import Portfolio from '@/assets/portfolio.svg?react';
import Team from '@/assets/Team.svg?react';
import Transactions from '@/assets/transactions.svg?react';
import Transfer from '@/assets/Transfer.svg?react';
import NftCard from '@/components/ui/nft-card';
import ProfileTabs from '@/components/ui/profile-tabs';
import { formatTimestamp, polygonScanLink } from '@/const';
import {
  useAllTransactionsTable,
  useHoldingAssetsTable,
  useRecentTransactionsTable,
} from '@/hooks/useTableConfigs';
import {
  GetMarketplaceInfoDocument,
  GetUserAssetsDocument,
  GetUserByNumberDocument,
  GetUserEventsByNumberDocument,
  GetUserNftsByNumberDocument,
  GetUserTransactionsByNumberDocument,
  GetUserUtilitiesByNumberDocument,
  UserHasReferralsDocument,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import { formatToStoreUserTransactions } from '@/lib/utils/userTransactions';

import Table from '../ui/alt-table';
import SectionDivider from '../ui/section-divider';
import UtilCard from '../ui/utility-card';

const Users: FunctionComponent<any> = () => {
  const [events, setEvents] = useState<any>([]);
  const [assets, setAssets] = useState([]);
  const [userProfile, setUserProfile] = useState<any>({});
  const [assetsInitialLoad, setAssetsInitialLoad] = useState(true);
  const [userHasReferrals, setUserHasReferrals] = useState(false);
  const [eventsLoading, setEventsLoading] = useState(true);
  const [profileLoading, setProfileLoading] = useState(true);
  const [isSecurities, setIsSecurities] = useState<boolean | null>(null);

  const [activeTab, setActiveTab] = useState(0);
  const [userAssets, setUserAssets] = useState([]);
  const [userAssetsLoading, setUserAssetsLoading] = useState(true);
  const [userTransactions, setUserTransactions] = useState([]);
  const [userTransactionsLoading, setUserTransactionsLoading] = useState(true);
  const [userUtils, setUserUtils] = useState<UtilCardType[]>([]);

  const { id } = useParams();
  const navigate = useNavigate();

  const userInfo = [
    { label: 'Created', value: formatTimestamp(userProfile?.created_at) },
    { label: 'Balance', value: `$${(userProfile?.project_balance / 1e18).toFixed(2)}` },
    { label: 'Has Referrals', value: userHasReferrals ? 'Yes' : 'No' },
    // { label: 'Is Accredited', value: 'Incomplete' },
  ];
  const handleGetUserEvents = async () => {
    try {
      const {
        data: { getUserEventsByNumber: getUserEventsRes },
      } = await client.query({
        query: GetUserEventsByNumberDocument,
        variables: {
          input: {
            batch_size: 20,
            page_number: 0,
          },
          number: parseInt(`${id}`),
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getUserEventsRes });
      setEvents(getUserEventsRes);
      setEventsLoading(false);
    } catch (err) {
      console.log(err);
      setEventsLoading(false);
    }
  };

  const handleGetUserAssets = async () => {
    try {
      // if () {
      //   const {
      //     data: {
      //       getUserNftsByNumber: { nfts: nftsRes },
      //     },
      //   } = await client.query({
      //     query: GetUserNftsByNumberDocument,
      //     variables: {
      //       number: parseInt(`${id}`),
      //     },
      //     context: {
      //       clientName: ClientName.Marketplace,
      //     },
      //   });
      //   console.log({ nftsRes });
      //   setAssetsInitialLoad(false);
      //   setAssets(nftsRes);
      // }
      // else {
      const {
        data: { getUserAssets: getUserAssetsRes },
      } = await client.query({
        query: GetUserAssetsDocument,
        variables: {
          input: {
            user_number: parseInt(`${id}`),
          },
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getUserAssetsRes });
      setUserAssetsLoading(false);
      setUserAssets(getUserAssetsRes);
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetUserProfile = async () => {
    try {
      const {
        data: { getUserByNumber: getUserProfileRes },
      } = await client.query({
        query: GetUserByNumberDocument,
        variables: {
          number: parseInt(`${id}`),
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getUserProfileRes });
      setUserProfile(getUserProfileRes);
      setProfileLoading(false);
      handleGetUserHasReferrals(getUserProfileRes.email);
    } catch (err) {
      console.log(err);
      setProfileLoading(false);
    }
  };

  const handleGetUserHasReferrals = async (wallet_address) => {
    try {
      const {
        data: { userHasReferrals: getUserHasReferralsRes },
      } = await client.query({
        query: UserHasReferralsDocument,
        variables: {
          user_id: wallet_address,
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getUserHasReferralsRes });
      setUserHasReferrals(getUserHasReferralsRes);
    } catch (getUserHasReferralsErr) {
      console.log({ getUserHasReferralsErr });
    }
  };

  const handleGetUserUtils = async () => {
    try {
      const {
        data: { getUserUtilitiesByNumber: getUserUtilitiesRes },
      } = await client.query({
        query: GetUserUtilitiesByNumberDocument,
        variables: {
          number: parseInt(`${id}`),
        },
        context: {
          clientName: ClientName.Marketplace,
        },
      });
      console.log({ getUserUtilitiesRes });
      setUserUtils(getUserUtilitiesRes.utilities);
    } catch (getUserHasReferralsErr) {
      console.log({ getUserHasReferralsErr });
    }
  };

  const assetsWithLoad = useMemo(() => {
    if (assetsInitialLoad) {
      return [
        { loading: true, batch_id: '' },
        { loading: true, batch_id: '' },
        { loading: true, batch_id: '' },
      ];
    } else {
      return assets;
    }
  }, [assets, assetsInitialLoad]);

  const eventsData = useMemo(() => {
    return {
      columnNames: ['Event', 'Quantity', 'Price', 'Date'],
      data: events
        ?.filter((item) => item.event_type !== 'TransferSingle')
        ?.map((item) => [
          {
            value: item.event_type,
            className: 'text-md',
          },
          {
            value: item.quantity,
            className: 'text-md',
          },
          {
            value: `$${(parseInt(item.ui_price) / 100).toFixed(2)}`,
            className: 'text-md',
          },
          {
            value: formatTimestamp(item.created_at),
            className: 'text-md',
          },
        ]),
    };
  }, [events]);

  const handleGetUserTransactions = async () => {
    const walletAddress = `jawad@chainstarters.com`;
    if (!walletAddress) {
      console.error('fetchUserTransactions | missing walletAddress');
      return;
    }

    // const rawUserTransactionsRes = await getUserTransactions();
    const {
      data: { getUserTransactionsByNumber: getUserTransactionsRes },
    } = await client.query({
      query: GetUserTransactionsByNumberDocument,
      variables: {
        number: parseInt(`${id}`),
      },
      context: {
        clientName: ClientName.Marketplace,
      },
    });
    console.log({ getUserTransactionsRes });
    const userTransactions_ = formatToStoreUserTransactions(getUserTransactionsRes, walletAddress);
    setUserTransactions(userTransactions_);
    setUserTransactionsLoading(false);
    // set({
    //   userTransactions,
    //   userTransactionsLoading: false,
    // });
  };

  const handleGetMarketplaceInfo = async () => {
    const {
      data: { getMarketplaceInfo: getMarketplaceInfoRes },
    } = await client.query({
      query: GetMarketplaceInfoDocument,
      // variables: {
      //   number: parseInt(`${id}`),
      // },
      context: {
        clientName: ClientName.Marketplace,
      },
    });
    console.log({ getMarketplaceInfoRes });
    setIsSecurities(getMarketplaceInfoRes.ui_env.IS_SECURITIES === 'true');
  };

  useEffect(() => {
    handleGetMarketplaceInfo();
    handleGetUserProfile();
    handleGetUserEvents();
    handleGetUserAssets();
    handleGetUserTransactions();
    handleGetUserUtils();
  }, []);

  const is_securities = true;

  const formattedTypesObj = {
    ownerTransferFrom: 'Transfer',
    priceMintWithERC20: 'Mint',
    mint: 'Mint',
    mintWithCC: 'Mint',
    purchaseWithERC20: 'Purchase',
    safeTransferFrom: `Asset Transfer`,
  };

  const formatNumber = (num, erc = 0) => {
    if (erc == 1155) {
      return (parseFloat(num) / 100)?.toFixed(num < 0.001 ? 4 : num < 0.01 ? 3 : 2);
    } else {
      return parseFloat(num)?.toFixed(num < 0.001 ? 4 : num < 0.01 ? 3 : 2);
    }
  };

  const formatUnits = (quantity, decimals) => {
    const divisor = Math.pow(10, decimals ?? 18);
    return (quantity / divisor).toString();
  };

  const handleGetTransactionQuantity = (item) => {
    if (!item) return '0';
    const { primary_event, asset_info } = item;
    if (!primary_event || !asset_info) return '0';

    let data;
    try {
      data = JSON.parse(primary_event.data);
    } catch (e) {
      console.log(e);
    }

    if (!data) return '0';

    const isPositive = data.to === userProfile?.wallet_address || !item.asset_info.decimals;
    const ret = isPositive ? '' : '- ';
    if (primary_event.payout) {
      return `${ret} ${formatUnits(item.primary_event.quantity, item.asset_info.decimals)} Payout`;
    } else if (item.asset_info.erc == 20) {
      return `${ret} ${parseFloat(
        formatUnits(item.primary_event.quantity, item.asset_info.decimals ?? 18),
      ).toLocaleString()}`;
    } else {
      // probably NFT
      return ret + parseFloat(item.primary_event.quantity).toLocaleString();
    }
  };

  const holdingAssetsTable = useHoldingAssetsTable(userAssets, formatNumber, navigate);

  const allTransactionsTable = useAllTransactionsTable(
    userTransactions,
    formattedTypesObj,
    handleGetTransactionQuantity,
    polygonScanLink,
  );

  useEffect(() => {
    console.log({ allTransactionsTable });
  }, [allTransactionsTable]);

  return (
    <div className="grid w-full max-w-[1100px] flex-col gap-[25px]">
      <SectionDivider titles={[`View Profile`]} img={<Team stroke="white" />} color="#ff6661" />
      <div className="flex">
        <img
          className={cn(
            'h-[120px] w-[120px] rounded-[60px] bg-card',
            profileLoading ? 'animate-pulse' : '',
          )}
          src={profileLoading ? '' : userProfile?.photo || DefaultProfileImage}
        />
        <div className="ml-[30px] flex flex-col gap-[8px] border-l pl-[30px]">
          {profileLoading ? (
            <div className="loading my-[2px] h-[24px] w-[100px] animate-pulse rounded-[6px]" />
          ) : (
            <h1 className="text-xl font-medium">{userProfile?.name}</h1>
          )}
          {profileLoading ? (
            <div className="loading my-[2px] h-[20px] w-[200px] animate-pulse rounded-[6px]" />
          ) : (
            <p className="text-md">{userProfile?.user_id}</p>
          )}
          <div className="mt-[10px]">
            {userInfo.map((item) => (
              <div key={`${item.label}:`} className="flex opacity-60">
                <h1 className="text-md w-[150px]">{item.label}</h1>
                {profileLoading ? (
                  <div className="loading my-[2px] h-[20px] w-[100px] animate-pulse rounded-[6px]" />
                ) : (
                  <p className="text-md">{item.value}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {isSecurities === null ? (
        <div className="mt-[100px] flex min-h-[95px] w-full items-center justify-center">
          <div className="size-10 animate-spin rounded-full border-t-2 border-primary" />
        </div>
      ) : (
        <>
          {!isSecurities ? (
            <>
              <SectionDivider
                titles={[`User Purchases`]}
                img={<Transfer className="stroke-white" stroke="white" />}
                color="#4555ff"
              />
              <div className="rounded-[10px] bg-card pb-[20px] pr-[10px] pt-[10px] shadow-md">
                <Table
                  ariaLabel="user-events-table"
                  columnNames={eventsData.columnNames}
                  data={eventsData.data}
                  isLoading={eventsLoading}
                  onRowClick={(item, index) => {}}
                />
              </div>
              <SectionDivider
                titles={[assetsInitialLoad ? 'Owned Assets' : `Owned Assets (${assets.length})`]}
                img={<NFTImg className="stroke-white" stroke="white" />}
                color="#9256ff"
              />
              <div className="flex w-full flex-wrap gap-[20px]">
                {!assetsInitialLoad && assetsWithLoad.length == 0 ? (
                  <div className="flex h-[340px] w-full items-center justify-center rounded-[10px] bg-card pb-[20px] pr-[10px] pt-[10px] shadow-md">
                    <p className="opacity-60">No Data</p>
                  </div>
                ) : (
                  assetsWithLoad.map((nft, index) => (
                    <NftCard
                      key={index}
                      loading={nft.loading}
                      asset={nft}
                      onClick={() => {
                        if (nft.batch_id) {
                          navigate(`/asset/${nft.batch_id}`);
                        }
                      }}
                    />
                  ))
                )}
              </div>
            </>
          ) : (
            <div className="mb-[32px] mt-[20px] w-full max-w-[1100px]">
              <ProfileTabs activeTab={activeTab} setActiveTab={setActiveTab} />
              <div
                style={{ maxWidth: 'calc(100vw - 535px)' }}
                className="w-full min-w-full max-w-[1100px] overflow-x-scroll pt-[10px]"
              >
                {activeTab === 0 && (
                  <Table
                    ariaLabel="holding-assets-table"
                    columnNames={holdingAssetsTable.columnNames}
                    data={holdingAssetsTable.data}
                    isLoading={userAssetsLoading}
                    className="w-full min-w-[1200px]"
                    cellClassName={'p-[0px] pl-[24px]'}
                    rowClassName="py-[20px] h-[100px]"
                    accordionClassName="py-[20px] h-[80px]"
                    onRowClick={(item, index) => {
                      console.log({ item });
                    }}
                  />
                )}
                {activeTab === 1 && (
                  <Table
                    ariaLabel="holding-assets-table"
                    columnNames={allTransactionsTable.columnNames}
                    data={allTransactionsTable.data}
                    isLoading={userTransactionsLoading}
                    className="w-full min-w-[1200px]"
                    cellClassName={'p-[0px] pl-[24px]'}
                    rowClassName=" py-[20px] h-[100px]"
                    onRowClick={(item, index) => {
                      console.log({ item });
                    }}
                  />
                )}
                {activeTab === 2 && (
                  <div className="grid min-w-full grid-cols-3 gap-[20px] pb-[20px] pt-[8px]">
                    {userUtils.map((item, index) => (
                      <UtilCard
                        key={`util_${index}`}
                        name={item?.name}
                        description={item?.description}
                        image={item?.details?.cover_image_cid}
                        active={item?.details?.active}
                        onClick={() => {
                          navigate(`/utility-view/${item?.utilityId}`);
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Users;

type UtilCardType = {
  name: string;
  description: string;
  utilityId: string;
  details: {
    cover_image_cid: string;
    active: boolean;
  };
};

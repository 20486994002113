import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AddButton } from '@/components/ui/add-button';
import { Input } from '@/components/ui/input';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import { cn } from '@/lib/utils';

const SiteBuilder = () => {
  const methods = useForm({
    defaultValues: {
      problemToggled: true,
      solutionToggled: true,
      productToggled: true,
      tractionToggled: true,
      businessModelToggled: true,
      marketToggled: true,
      competitionToggled: true,
      visionModelToggled: true,
      investorsToggled: true,
      advisorToggled: true,
      teamToggled: true,
    },
  });

  const sectionClassName =
    'relative mb-[32px] grid w-full gap-[16px] rounded-[8px] bg-card p-[28px] shadow-[2px_2px_24px_0px_rgba(0,0,0,0.1)] sm:p-[48px]';

  const innerSectionClassName =
    'bg-[#f9f9f9] p-[24px] flex flex-col gap-[24px] w-full rounded-[16px] mt-[24px]';

  const MOCKData = [
    {
      title: 'Transfer Agent Registration',
      sectionClassName,
      inputs: [
        {
          type: 'input',
          label: 'Company Name',
          state: 'companyName',
          placeholder: 'Enter company name',
        },
        {
          type: 'textarea',
          label: 'Company Description',
          textarea: true,
          state: 'companyDescription',
          placeholder: 'Enter company description',
        },
        { type: 'imageUpload', label: 'Favicon', state: 'favicon', smallImg: true },
        {
          type: 'imageUpload',
          label: 'Video Overview',
          state: 'videoOverview',
        },
        {
          type: 'checkboxColumn',
          label: 'Website Theme',
          state: 'websiteTheme',
          placeholder: 'Select website theme',
          options: [
            { label: 'Light', value: 'Light' },
            { label: 'Dark', value: 'Dark' },
          ],
        },
        {
          type: 'color',
          label: 'Primary color',
          state: 'primaryColor',
          placeholder: 'Select primary color',
          halfWidth: true,
        },
        {
          type: 'color',
          label: 'Secondary color',
          state: 'secondaryColor',
          placeholder: 'Select secondary color',
          halfWidth: true,
        },
        {
          type: 'input',
          label: 'Highlights (list 4-6)',
          state: 'highlights',
          placeholder: 'Enter highlights',
        },
        {
          type: 'input',
          label: 'Funding Goal',
          state: 'fundingGoal',
          placeholder: 'Enter funding goal',
        },
        {
          type: 'checkboxColumn',
          label: 'Security Type',
          state: 'securityType',
          placeholder: 'Select security type',
          options: [
            { label: 'SAFE', value: 'SAFE' },
            { label: 'Convertible Note', value: 'Convertible Note' },
            { label: 'Equity Financing', value: 'Equity Financing' },
          ],
        },
        {
          type: 'input',
          label: 'Minimum Investment',
          state: 'minimumInvestment',
          placeholder: 'Enter minimum investment',
          halfWidth: true,
        },
        {
          type: 'input',
          label: 'Maximum Investment',
          state: 'maximumInvestment',
          placeholder: 'Enter maximum investment',
          halfWidth: true,
        },
        {
          type: 'input',
          label: 'Closing Date',
          state: 'closingDate',
          placeholder: 'Enter closing date',
        },
        {
          type: 'checkboxColumn',
          label: 'Offering Type',
          state: 'offeringType',
          placeholder: 'Select offering type',
          options: [
            { label: 'Reg A+', value: 'Reg A+' },
            { label: 'Reg CF', value: 'Reg CF' },
            { label: 'Reg D', value: 'Reg D' },
          ],
        },
        {
          type: 'input',
          label: 'URL link to Form A+, Form CF, or Form D',
          state: 'urlLinkToForm',
          placeholder: 'Enter URL link to form',
        },
        {
          type: 'imageUpload',
          label: 'Other Offering Agreements',
          state: 'otherOfferingAgreements',
        },
      ],
    },
  ];

  const MOCKData2 = [
    {
      title: 'Problem',
      sectionClassName,
      hasToggle: true,
      toggleState: 'problemToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'header',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'description',
          placeholder: 'Enter description',
        },
      ],
    },
    {
      title: 'Solution',
      sectionClassName,
      hasToggle: true,
      toggleState: 'solutionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'header',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'description',
          placeholder: 'Enter description',
        },
        {
          type: 'imageUpload',
          label: 'Solution Image',
          state: 'solutionImage',
        },
      ],
    },
    {
      title: 'Product',
      sectionClassName,
      hasToggle: true,
      toggleState: 'productToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'header',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'description',
          placeholder: 'Enter description',
        },
        {
          type: 'imageUpload',
          label: 'Solution Image',
          state: 'solutionImage',
        },
      ],
    },
    {
      title: 'Traction',
      sectionClassName,
      hasToggle: true,
      toggleState: 'tractionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'header',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'description',
          placeholder: 'Enter description',
        },
      ],
    },
    {
      title: 'Business Model ',
      sectionClassName,
      hasToggle: true,
      toggleState: 'businessModelToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'header',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'description',
          placeholder: 'Enter description',
        },
      ],
    },
    {
      title: 'Market',
      sectionClassName,
      hasToggle: true,
      toggleState: 'marketToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'header',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'description',
          placeholder: 'Enter description',
        },
        {
          type: 'imageUpload',
          label: 'Solution Image',
          state: 'solutionImage',
        },
      ],
    },
    {
      title: 'Competition',
      sectionClassName,
      hasToggle: true,
      toggleState: 'competitionToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'header',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'description',
          placeholder: 'Enter description',
        },
      ],
    },
    {
      title: 'Vision Model ',
      sectionClassName,
      hasToggle: true,
      toggleState: 'visionModelToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'header',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'description',
          placeholder: 'Enter description',
        },
      ],
    },
    {
      title: 'Investors',
      sectionClassName,
      hasToggle: true,
      toggleState: 'investorsToggled',
      inputs: [
        {
          type: 'input',
          label: 'Header',
          state: 'header',
          placeholder: 'Enter header',
        },
        {
          type: 'textarea',
          label: "Investor's description",
          state: 'investorsDescription',
          placeholder: "Enter investor's description",
        },
      ],
    },
  ];

  const MOCKData25 = useCallback(
    (title = '', typeOfMember = '') => [
      {
        title,
        sectionClassName: 'w-full gap-[24px] flex flex-col',
        hasToggle: true,
        toggleState: `${typeOfMember}Toggled`,
        inputs: [
          {
            type: 'input',
            label: 'Header',
            state: 'header',
            placeholder: 'Enter header',
          },
          {
            type: 'textarea',
            label: `${typeOfMember}'s description`,
            state: 'advisorsDescription',
            placeholder: "Enter advisor's description",
          },
        ],
      },
    ],
    [],
  );

  const additionalAdvisorInput = [
    {
      type: 'input',
      label: "Advisor's name",
      state: 'advisorsName',
      placeholder: 'Enter header',
    },
    {
      type: 'textarea',
      label: "Advisor's bio",
      state: 'advisorsBio',
      placeholder: "Enter advisor's description",
    },
    {
      type: 'imageUpload',
      label: "Advisor's photo",
      state: 'advisorsPhoto',
    },
  ];
  const additionalTeamInput = [
    {
      type: 'input',
      label: "Team member's name",
      state: 'teamsName',
      placeholder: 'Enter header',
    },
    {
      type: 'textarea',
      label: "Team member's bio",
      state: 'teamsBio',
      placeholder: "Enter Team's description",
    },
    {
      type: 'imageUpload',
      label: "Team member's photo",
      state: 'teamsPhoto',
    },
  ];

  const [MOCKData3, setMOCKData3] = useState([
    {
      sectionClassName: innerSectionClassName,
      inputs: additionalAdvisorInput,
    },
  ]);
  const [MOCKData4, setMOCKData4] = useState([
    {
      sectionClassName: innerSectionClassName,
      inputs: additionalTeamInput,
    },
  ]);

  const MOCKData5 = [
    {
      title: 'FAQs',
      sectionClassName,
      inputs: [
        {
          type: 'input',
          label: 'First question',
          state: 'firstQ',
          placeholder: 'Enter first question',
        },
      ],
    },
    {
      title: ' Social Links',
      sectionClassName,
      inputs: [
        {
          type: 'input',
          state: 'linkedInURL',
          placeholder: 'LinkedIn URL',
          halfWidth: true,
        },
        {
          type: 'input',
          state: 'instagramURL',
          placeholder: 'Instagram URL',
          halfWidth: true,
        },
        {
          type: 'input',
          state: 'facebookURL',
          placeholder: 'Facebook URL',
          halfWidth: true,
        },
        {
          type: 'input',
          state: 'twitterURL',
          placeholder: 'Twitter URL',
          halfWidth: true,
        },
      ],
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mx-[20px] flex flex-col items-center gap-[40px]">
      <div className="flex h-[700px] w-full max-w-[80%] flex-col items-center justify-center gap-[40px] lg:w-[820px]">
        <h1 className="text-center  text-4xl font-semibold sm:text-6xl">
          Company Website Information
        </h1>
        <p className="text-center opacity-80">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
          sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </p>
        <button className="w-full rounded-[4px] bg-primary px-[32px] py-[16px] text-base uppercase text-white ">
          View My Website
        </button>
      </div>
      <div className="w-full max-w-[850px]">
        <h2 className="mb-[48px] text-center text-4xl font-normal">
          Fill the form to create your website
        </h2>
        <WrappedInputSection methods={methods} data={MOCKData} />
        <div className="relative mb-[32px] grid w-full gap-[24px] rounded-[8px] bg-card p-[28px] shadow-[2px_2px_24px_0px_rgba(0,0,0,0.1)] sm:p-[48px]">
          <h3 className="text-lg font-semibold">Connect Your Domain</h3>
          <p className="text-sm">
            <span className="mr-[8px] font-semibold">1.</span> Go to your domain provider.
          </p>
          <p className="text-sm">
            <span className="mr-[8px] font-semibold">2.</span> Create a CNAME record.
          </p>
          <ul>
            <li className="ml-[32px] flex items-center text-sm">
              <div className="mr-3 size-[8px] rounded-[5px] bg-[#000000]" /> This is where you will
              designate designate what you want your URL to be
            </li>
          </ul>
          <p className="text-sm">
            <span className="mr-[8px] font-semibold">3.</span> Add this URL to the CNAME record you
            just created:
          </p>
          <ul>
            <li className="ml-[32px] flex items-center text-sm text-primary">
              <div className="mr-3 size-[8px] rounded-[5px] bg-primary" />
              ad85f8aafd4ab45499c05554607875f5-2040465497.us-west-2.elb.amazonaws.com
            </li>
          </ul>
          <p className="text-sm">
            <span className="mr-[8px] font-semibold">4.</span> Navigate back to the dashboard and
            enter your custom URL.
          </p>
          <p className="text-sm">
            <span className="mr-[8px] font-semibold">5.</span> Click connect.
          </p>
          <button className="w-[250px] rounded-[4px] bg-primary px-[32px] py-[16px] text-base uppercase text-white ">
            CONNECT
          </button>
        </div>
        <WrappedInputSection methods={methods} data={MOCKData2} />

        <div className={cn(sectionClassName)}>
          <WrappedInputSection
            methods={methods}
            data={MOCKData25('Investors and Advisors', 'advisor')}
          />
          <WrappedInputSection methods={methods} data={MOCKData3} />
          <AddButton
            onClick={(e) => {
              setMOCKData3([
                ...MOCKData3,
                {
                  sectionClassName: innerSectionClassName,
                  inputs: additionalAdvisorInput,
                },
              ]);
            }}
          />
          <Input label="Disclaimers" placeholder="Disclaimers" />
        </div>
        <div className={cn(sectionClassName)}>
          <WrappedInputSection methods={methods} data={MOCKData25('Team', 'team')} />
          <WrappedInputSection methods={methods} data={MOCKData4} />
          <AddButton
            onClick={(e) => {
              setMOCKData4([
                ...MOCKData4,
                {
                  sectionClassName: innerSectionClassName,
                  inputs: additionalTeamInput,
                },
              ]);
            }}
          />
        </div>
        <WrappedInputSection methods={methods} data={MOCKData5} />
        <div className="flex w-full">
          <button className="mb-[48px] ml-auto w-full rounded-[4px] bg-primary px-[32px] py-[16px] text-base uppercase text-white sm:w-auto">
            Save & Continue
          </button>
        </div>
      </div>
      {/* <div></div> */}
    </div>
  );
};

export default SiteBuilder;
